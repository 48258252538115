<template>
  <div>
    <div class="container paymentCard">
       <!-- <div class="cart-header">
        <b-button icon-left="times" style="border: inherit;float: right;" class="cart-header-btn-close" @click="$parent.close()"></b-button>
      </div> -->
      <!-- <div class="title-payment">
        <div class="arrow" @click="$parent.close()">
           <i style="color:black" class="fas fa-arrow-left"></i>
        </div>
        <span class="text bold segoe size-23 margin-bot-10">Finaliza tu pedido</span>
      </div> -->
      <form class="cont" @submit.prevent="validateBeforePse">
        <div class="columns">
          <!-- <div class="column">
            <div class="pay-cont">
                <span class="text bold segoe size-16"><i class="fas fa-credit-card"></i>  Tarjeta de crédito</span>
                <b-radio disabled v-model="radioCard"
                native-value="0">
                </b-radio>
            </div>
            <div class="pay-cont">
                <span class="text bold segoe size-16"> <i class="far fa-credit-card"></i>  Tarjeta débito</span>
                <b-radio v-model="radioCard"
                    native-value="1">
                </b-radio>
            </div>
          </div> -->
          <div class="column">
            <b-field label="Banco:" :type="{'is-danger': errors.has('bank')}" :message="errors.first('bank')">
              <b-select name="bank" v-model="pse.transaction_details.financial_institution" placeholder="-- Seleccione --"  id="bank" v-validate="'required'" expanded>
                <option v-for="(bank, b) in banks" :value="bank.id" :key="'bank#' + b">{{ bank.description }}</option>
              </b-select>
            </b-field>
            <b-field label="Email*" :type="{'is-danger': errors.has('email')}" :message="errors.first('email')">
              <b-input type="text"
                name="email"
                id="email"
                placeholder="Email"
                v-model="pse.payer.email"
                v-validate="'required|email'" expanded icon-pack="fa"></b-input>
            </b-field>
            <div style="display:flex">
              <b-field  label="Tipo de id*:" style="min-width: 100px;width:100px;margin-right: 10px;" :type="{'is-danger': errors.has('typeDoc')}" :message="errors.first('typeDoc')">
                <b-select name="typeDoc" v-model="pse.payer.identification.type" placeholder="Tipo id"  id="typeDoc" v-validate="'required'" expanded>
                  <option v-for="(typeP, tp) in typePerson" :value="typeP.id" :key="'bank#' + tp">{{ typeP.name }}</option>
                </b-select>
              </b-field>
              <b-field label="Identificación*" style="width: 100%;" :type="{'is-danger': errors.has('identification')}" :message="errors.first('identification')">
                <b-input  type="text"
                  name="identification"
                  id="identification"
                  placeholder="Documento de identidad"
                  v-model="pse.payer.identification.number"
                  v-validate="'required|numeric|min:5'" expanded icon-pack="fa"></b-input>
              </b-field>
            </div>
          <b-radio
                type="radio"
                name="typePeople"
                native-value="individual"
                v-model="pse.payer.entity_type"
                v-validate="'required|included:individual,association'">
              <span class="text segoe size-16">Persona Natural</span>
          </b-radio>
          <b-radio
                type="radio"
                name="typePeople"
                native-value="association"
                v-validate="'required|included:individual,association'"
                v-model="pse.payer.entity_type">
              <span class="text segoe size-16">Persona Juridica</span>
          </b-radio>
          <span v-show="errors.has('typePeople')" class="text-danger">
            {{ errors.first('typePeople') }}
          </span>
        </div>
        </div>
        <div class="terms pay">
          <div class="copy-terms">
            <b-checkbox class="terms-label" v-model="authTerms"></b-checkbox>&nbsp;<span class="text segoe"> Al dar clic en "Pagar" declara que conoce y acepta el tratamiento de datos personales previamente informado y al enviar este formato a Precompro acepta nuestros  </span>
            <a @click="showTerms = true" class="link-terms">Términos y condiciones y la Política de Privacidad*</a>.
            <span class="text segoe">Para cumplir con el objeto social de Precompro, al hacer la reserva se compartirá su información con el restaurante aliado. </span>
          </div>
        </div>
        <div class="columns">
          <div class="column bottom-text">
          </div>
          <div class="column bottom-text-r padding-500"
            style="margin-right: 0px; text-align: right; padding: 30px 0px 0px 0px;">
           <span class="text segoe" style="vertical-align: super;"> Pagos seguros a través de </span> &nbsp;<img src="@/assets/image/mercadoPago.svg" style="width: 2.5rem; margin-left: 10px; margin-right: 10px;">
          </div>
        </div>
        <div id="modal-terms">
          <b-modal :active.sync="showTerms">
            <div class="terms-body">
              <h3 class="title-modal">Política Tratamiento de Datos Personales de Click2buy S.A.S.</h3>
              <terms :fecha="'22 de Noviembre del 2019'"></terms>
            </div>
          </b-modal>
        </div>
        <div class="btn-pay-content">
          <button type="submit"
            class="btn btn-reserva-disabled"
            :disabled="(loader || !authTerms)"
            :class="{'opacity06':(pse.payer.identification.type === null || pse.payer.identification.type === '' ||
                pse.payer.identification.number === null || pse.payer.identification.number === '' ||
                pse.payer.email === null || pse.payer.email === '' ||
                pse.payer.entity_type === null || pse.payer.entity_type === '' ||
                    pse.transaction_details.financial_institution === null || pse.transaction_details.financial_institution === '')}">
                    <span class="text size-18 family-bold">Realizar pago</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import terms from '@/components/Terms';
import LocalStorage from 'store';
import _ from 'lodash';

export default {
  components: { terms },
  props: ['order'],
  data () {
    return {
      banks: [],
      typePerson: [],
      pse: {
        transaction_amount: 0,
        net_amount: 0,
        taxes: [
          {
            value: 0,
            type: 'IVA'
          }
        ],
        description: null,
        payment_method_id: 'pse',
        binary_mode: true,
        payer: {
          email: null,
          entity_type: null,
          identification: {
            type: null,
            number: null
          }
        },
        transaction_details: {
          financial_institution: null
        },
        callback_url: null
      },
      authTerms: true,
      showTerms: false,
      radioCard: 1
    };
  },
  computed: {
    ...mapGetters([
      'loader'
    ])
  },
  methods: {
    selectPayment () {
      this.$store.commit('setPaymentType', false);
    },
    validateBeforePse () {
      this.errors.clear();
      this.$validator.validateAll().then(result => {
        if (result) {
          this.proccessPse(this.card);
        }
      });
    },
    proccessPse () {
      this.$store.commit('setLoader', true);
      this.order.mp = 1;
      this.$http.post('marketplace/payment/pse', { order: this.order, pse: this.pse }).then(response => {
        if (response.data.code === 200) {
          LocalStorage.set('processId', response.data.data.id);
          window.location.href = response.data.url;
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Operación fallida <br><strong style='color: #ffffff;'>Ocurrió un error al intentar realizar el pago, por favor intenta de nuevo</strong> ",
            type: 'is-danger',
            position: 'is-bottom',
            queue: false
          });
          this.$store.commit('setLoader', false);
        }
      }).catch(() => {
        this.$store.commit('setLoader', false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Ocurrio un error al procesar la petición con la pasarela de pago <br><strong style='color: #ffffff;'>Intenta realizar el pago de nuevo</strong> ",
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        });
      });
    },
    getPaymentOptions () {
      this.$http.get('marketplace/payment/banks/pse').then(({ data }) => {
        if (data.code === 200) {
          const info = _.find(data.data, { id: 'pse' });
          this.banks = info.financial_institutions;
        } else {
          this.banks = [];
        }
      });
    },
    getIdentification () {
      this.$http.get('marketplace/payment/identifications').then(({ data }) => {
        if (data.code === 200) {
          this.typePerson = data.data;
        } else {
          this.typePerson = [];
        }
      });
    }
  },
  mounted () {
    this.getPaymentOptions();
    this.getIdentification();
    this.pse.transaction_amount = this.order.totalPrice + this.order.delivery;
    this.pse.net_amount = this.order.totalPrice + this.order.delivery;
    const trans = this.$route.query;
    if (Object.keys(trans).length > 0) {
      if (trans.source_utm) {
        this.pse.callback_url = window.location.href;
      } else {
        this.pse.callback_url = window.location.href + '?source_utm=mp';
      }
    } else {
      this.pse.callback_url = window.location.href + '?source_utm=mp';
    }
  }
};
</script>
<style lang="scss">
.container{
    //new scss
  .arrow{
    position: absolute;
    left: -8px;
  }
  .cont{
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    input{
    box-shadow: inherit;
    border-radius: 9px;
    height: 32px;
    font-size: 10px;
    font-family: segoe;
    padding: 0 10px;
    border: 1px solid rgba(3, 15, 52, 0.42);
    text-align: left;
    color: black;
    }
    select{
    box-shadow: inherit;
    border-radius: 9px;
    height: 32px;
    font-size: 10px;
    font-family: segoe;
    padding: 0 10px;
    border: 1px solid rgba(3, 15, 52, 0.42);
    text-align: left;
    color: black;
    }
    .columns{
      &.wrap{
        flex-wrap: wrap;
        display: flex;
      }
    }
    .column{
      &.border-bot{
            border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
      }
    }
  }
  .title-payment{
    width: calc(100% - 30px);
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
  }
    .pay-cont{
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
    }
    // end scss
}
.hsy-dropdown > .list {
  width: 100% !important;
}
.text-danger {
  width: 100%;
  float: left;
  font-size: 12px;
  color: #ff3860;
}

.rigth-border {
  border-right: 1px solid #ccc !important;
}
.btn-pay {
  background: var(--btnBgReservationFooter);
  color: var(--btnColorReservationFooter) !important;
}
.btn-pay:disabled {
  background-color: rgba(181, 179, 179, 0.34) !important;
  color: #fff !important;
  border: 1px solid #ddd !important;
}
.btn-pay-content {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  button {
    background: var(--buttonBackground);
    color: var(--buttonTextColor) !important;
    .text{
          color: var(--buttonTextColor) !important;
    }
    border: inherit;
    width: 100%;
    width: 100%;
    max-width: 360px;
    border-radius: 6px;
    border: inherit;
    cursor: pointer;
  }
}
.list {
  height: 20vh;
  overflow: scroll;
}

.opacity06 {
  opacity: 0.6;
}
.img-card {
  height: 9rem;
}

select option {
  padding: 15px 0px;
}

@media (min-width: 768px) {
  .border-left {
    border-left: 1px solid lightgray;
  }
  // select {
  //   text-align-last: right;
  // }
  option {
    // direction: rtl;
    font-size: 12px;
  }
  .inputTrans {
    text-align: right;
  }
}
.v-select .dropdown-toggle {
  height: 32px;
}
.bottom-text, .bottom-text-r {
  font-size: 15px;
  -webkit-box-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: flex;
  padding: 5px;
  justify-content: flex-end;
}
.bottom-text {
  -webkit-box-pack: start;
  justify-content: flex-start;
}
</style>
