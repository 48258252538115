<template>
  <b-modal :active.sync="value" :can-cancel="false" class="ModalTransaction">
      <div class="modals-bosy">
          <div class="col-12 text-center">
            <IconCancel fill="#f44336" width="20em"></IconCancel>
          </div>
          <div>
            <h3 class="title-modal">Transacción Rechazada:</h3>
            <div class="col-12 text-lef-messages">
              <p>
                {{ msg }}
              </p>
              <h5 class="title-confirm">
                Por favor verifica otra forma de pago
              </h5>
            </div>
            <div class="btns-modal">
              <button
                type="button"
                @click="reintentar"
                class="full-btn-modal btn-modal-fill"><i class="fa fa-reply"></i>&nbsp; Volverlo a intentar con otro medio</button>
            </div>
          </div>
      </div>
  </b-modal>
</template>
<script>
import IconCancel from '@/components/icons/iconCancel';
export default {
  components: { IconCancel },
  props: ['value', 'msg'],
  methods: {
    reintentar () {
      this.$emit('input', false);
      // window.location.reload()
      this.$router.replace('/' + this.$route.params.id + '/order');
    }
  }
};
</script>
<style lang="scss" scoped>
.ModalTransaction{
  .modals-bosy {
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-modal{
      text-align: center;
    }
    .btns-modal{
      margin-top: 15px !important;
      display: flex;
      flex-wrap: wrap;
      button{
        width: 364px !important;
        padding: 10px !important;
        margin: auto !important;
        text-align:center;
        margin-top:10px !important;
      }
    }
  }
}
</style>
