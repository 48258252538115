<template>
  <svg  :style="'enable-background:new 0 0 1366 768; width:'+width" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 456.1 436">
  <g id="Capa_2" data-name="Capa 2">
    <g id="Capa_1-2" data-name="Capa 1">
      <path  :style="{fill: fill}"  d="M218,436h-.1A218.17,218.17,0,0,1,0,217.8,218,218,0,0,1,218,0h.1a216.67,216.67,0,0,1,88.6,18.9,18.4,18.4,0,1,1-15,33.6,179.58,179.58,0,0,0-73.6-15.7H218A181.12,181.12,0,0,0,36.8,217.9c-.1,99.9,81.2,181.2,181.1,181.3h.1A181.12,181.12,0,0,0,399.2,218.1V199.5a18.4,18.4,0,1,1,36.8,0v18.6A218,218,0,0,1,218,436Z"/>
      <path :style="{fill: fill}" d="M218,276.5a18.33,18.33,0,0,1-13-5.4l-59.9-59.9a18.46,18.46,0,1,1,26.1-26.1L218,232,424.6,25.4a18.46,18.46,0,0,1,26.1,26.1L231,271.1A18.33,18.33,0,0,1,218,276.5Z"/>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: ['fill', 'width']
};
</script>
