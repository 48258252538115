export default {
  custom: {
    name: {
      required: 'El nombre es obligatorio'
    },
    phone: {
      required: 'El teléfono es obligatorio',
      numeric: 'Ingrese solo números',
      min: 'Minímo son 7 caracteres',
      max: 'Ha superado el límite de 14 caracteres'
    },
    email: {
      required: 'El correo electrónico es obligatorio',
      email: 'El correo electrónico no es válido'
    },
    address: {
      required: 'La dirección es obligatoria',
      min: 'Minímo son 3 caracteres'
    },
    typeAddress: {
      required: 'El tipo de vía es obligatorio'
    },
    numberType: {
      required: 'El número es obligatorio'
    },
    numberAdress: {
      required: 'El número es obligatorio'
    },
    numberHome: {
      required: 'El número es obligatorio'
    },
    cardholderName: {
      required: 'El nombre del titular es obligatorio',
      min: 'Minímo son 2 caracteres'
    }
  }
};
