<template>
  <div id="cardMercadoPago">
    <div class="container paymentCard">
      <!-- <div class="cart-header">
        <b-button icon-left="times" style="border: inherit;float: right;" class="cart-header-btn-close" @click="$parent.close()"></b-button>
      </div> -->
      <!-- <div class="title-payment">
        <div class="arrow" @click="$parent.close()">
           <i style="color:black" class="fas fa-arrow-left"></i>
        </div>
        <span class="text bold segoe size-23 margin-bot-10">Finaliza tu pedido</span>
      </div> -->
      <form class="cont" @submit.prevent="validateBeforeCard" id="pay" name="pay">
        <input type="hidden" name="token" id="token" value="">
        <div class="columns">
          <div class="column">
            <!-- <div class="pay-cont">
                <span class="text bold segoe size-16"><i class="fas fa-credit-card"></i>  Tarjeta de crédito</span>
                <b-radio v-model="radioCard"
                native-value="0">
                </b-radio>
            </div>
            <div class="pay-cont">
                <span class="text bold segoe size-16"> <i class="far fa-credit-card"></i>  Tarjeta débito</span>
                <b-radio disabled v-model="radioCard"
                    native-value="1">
                </b-radio>
            </div> -->
            <b-field label="Titular de la tarjeta:" :type="{'is-danger': errors.has('cardholderName')}" :message="errors.first('cardholderName')">
              <b-input type="text"
                  v-model="card.payer.first_name"
                  placeholder="Nombre del titular"
                  v-validate="'required|min:2'"
                  id="cardholderName"
                  data-checkout="cardholderName"
                  icon-pack="fa" icon=""></b-input>
            </b-field>
            <div style="display:flex">
            <b-field style="min-width:100px;width: 100px;margin-right: 10px;" label="Tipo de id*" :type="{'is-danger': errors.has('docType')}" :message="errors.first('docType')">
                <b-select v-model="card.payer.identification.type" id="docType" name="docType" data-checkout="docType" placeholder="Tipo id" v-validate="'required'" expanded>
                  <option v-for="(identi, ide) in identification" :value="identi.id" :key="'typeIdenti#' + ide">{{ identi.name }}</option>
                </b-select>
            </b-field>
            <b-field  style="width:100%" label="Identificación: " :type="{'is-danger': errors.has('docNumber')}" :message="errors.first('docNumber')">
              <b-input type="text"
                  v-model="card.payer.identification.number"
                  placeholder="Documento de identidad"
                  autocomplete="off"
                  id="docNumber"
                  data-checkout="docNumber"
                  v-validate="'required|numeric|min:5'" icon-pack="fa" icon=""></b-input>
            </b-field>
            </div>
            <b-field label="Email:" :type="{'is-danger': errors.has('email')}" :message="errors.first('email')">
              <b-input
                type="email"
                name="email"
                id="email"
                v-model="card.payer.email"
                placeholder="Email"
                v-validate="'required|email'" icon-pack="fa" icon=""></b-input>
            </b-field>
          </div>
          <div class="column border-left">
            <span class="text bold segoe size-11">Datos tarjeta</span>
              <b-field label="Numero de la tarjeta:" :type="{'is-danger': errors.has('cardNumber')}" :message="errors.first('cardNumber')">
              <b-input
                type="text"
                v-model="cardNumber"
                id="cardNumber"
                data-checkout="cardNumber"
                placeholder="xxxx xxxx xxxx xxxx"
                v-validate="'required|credit_card'"
                v-cleave="masks.creditCard"
                expanded icon-pack="fa" icon=""
                onselectstart="return false" onpaste="return false" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false"></b-input>
            </b-field>
            <b-field label="Código de seguridad:" :type="{'is-danger': errors.has('securityCode')}" :message="errors.first('securityCode')">
              <b-input
                type="password"
                id="securityCode"
                data-checkout="securityCode"
                v-model="cvv"
                placeholder="xxxx"
                onselectstart="return false" onpaste="return false" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false"
                v-validate="'required|max:4|min:3|numeric'" icon-pack="fa" icon=""></b-input>
            </b-field>
            <div class="columns wrap">
              <b-field label="Mes:" class="column" :type="{'is-danger': errors.has('cardExpirationMonth')}" :message="errors.first('cardExpirationMonth')">
                <b-select v-model="mes" id="cardExpirationMonth" data-checkout="cardExpirationMonth" placeholder="Seleccione" v-validate="'required'" expanded>
                  <option v-for="(mes, m) in meses" :value="mes.value" :key="'mes#' + m">{{ mes.label }}</option>
                </b-select>
              </b-field>
              <b-field label="Año:" class="column" :type="{'is-danger': errors.has('cardExpirationYear')}" :message="errors.first('cardExpirationYear')">
                <b-select v-model="ano" placeholder="Seleccione"  id="cardExpirationYear" data-checkout="cardExpirationYear" v-validate="'required'" expanded>
                  <option v-for="(ano, a) in anos" :value="ano.value" :key="'ano#' + a">{{ ano.label }}</option>
                </b-select>
              </b-field>
              <b-field label="Nro Cuota:" class="column" :type="{'is-danger': errors.has('cuota')}" :message="errors.first('cuota')">
              <b-select name="cuota" v-model="card.installments" placeholder="Seleccione" v-validate="'required'" expanded>
                <option v-for="(cuota, y) in cuotas" :value="cuota.value" :key="'cuota#' + y">{{ cuota.label }}</option>
              </b-select>
            </b-field>
            </div>
            <div class="furute-box" v-if="false">
              Deseas guardar esta tarjeta para compras futuras
              <b-switch :value="true" id="saveCard" name="saveCard" checked="true" v-model="checked" type="is-success"></b-switch>
            </div>
          </div>
        </div>
        <div class="terms pay">
          <div class="copy-terms">
            <b-checkbox class="terms-label" v-model="authTerms"></b-checkbox>&nbsp; <span class="text segoe">Al dar clic en "Pagar" declara que conoce y acepta el tratamiento de datos personales previamente informado y al enviar este formato a Precompro acepta nuestros </span>
            <a @click="showTerms = true" class="link-terms">Términos y condiciones y la Política de Privacidad*</a>.
           <span class="test segoe">Para cumplir con el objeto social de Precompro, al hacer la reserva se compartirá su información con el restaurante aliado. </span>
          </div>
        </div>
        <div class="columns">
          <div class="column bottom-text">
          </div>
          <div class="column bottom-text-r padding-500"
            style="margin-right: 0px; text-align: right; padding: 30px 0px 0px 0px;">
            <span class="text segoe" style="vertical-align: super;"> Pagos seguros a través de</span> &nbsp;<img src="@/assets/image/mercadoPago.svg" style="width: 2.5rem; margin-right: 10px; margin-left: 10px;">
          </div>
        </div>
        <div id="modal-terms">
          <b-modal :active.sync="showTerms">
            <div class="terms-body">
              <h3 class="title-modal">Política Tratamiento de Datos Personales de Click2buy S.A.S.</h3>
              <terms :fecha="'22 de Noviembre del 2019'"></terms>
            </div>
          </b-modal>
        </div>
        <div class="row">
          <div class="col-12" style="padding-bottom: 30px;display: flex;justify-content: center;">
              <button type="submit"
                class="btn btn-reserva-disabled"
                :disabled="(loader || !authTerms)"
                :class="{'opacity06':(card.payer.first_name === null || card.payer.first_name === ''||
                  card.payer.identification.type === null || card.payer.identification.type === '' ||
                  card.payer.identification.number === null || card.payer.identification.number === '' ||
                  card.payer.email === null || card.payer.email === '' ||
                  cardNumber === null || cardNumber === '' ||
                  mes === null || mes === '' ||
                  cvv === null || cvv === '' ||
                  ano === null || ano === '' || card.installments === null || card.installments === '' || (errors.items.length > 0))}"
              >
                <span class="text size-18 family-bold">Realizar pago</span>
              </button>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import terms from '@/components/Terms';
import { mapGetters } from 'vuex';
import Cleave from 'cleave.js';
const cleave = {
  name: 'cleave',
  bind (el, binding) {
    const input = el.querySelector('input');
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind (el) {
    const input = el.querySelector('input');
    input._vCleave.destroy();
  }
};
export default {
  components: { terms },
  props: ['order'],
  directives: { cleave },
  data () {
    return {
      masks: {
        creditCard: { creditCard: true },
        custom: {
          numericOnly: true
        }
      },
      authTerms: true,
      showTerms: false,
      cvv: null,
      cardNumber: null,
      mes: null,
      ano: null,
      checked: true,
      doSubmit: false,
      card: {
        payer: {
          first_name: null,
          identification: {
            type: null,
            number: null
          },
          email: null
        },
        token: null,
        installments: null,
        description: null,
        payment_method_id: null,
        transaction_amount: 0,
        net_amount: 0,
        binary_mode: true,
        taxes: [
          {
            value: 0,
            type: 'IVA'
          }
        ]
      },
      identification: [],
      meses: [
        { value: '01', label: '01' },
        { value: '02', label: '02' },
        { value: '03', label: '03' },
        { value: '04', label: '04' },
        { value: '05', label: '05' },
        { value: '06', label: '06' },
        { value: '07', label: '07' },
        { value: '08', label: '08' },
        { value: '09', label: '09' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
      ],
      anos: [
        { value: '20', label: '20' },
        { value: '21', label: '21' },
        { value: '22', label: '22' },
        { value: '23', label: '23' },
        { value: '24', label: '24' },
        { value: '25', label: '25' },
        { value: '26', label: '26' },
        { value: '27', label: '27' },
        { value: '28', label: '28' },
        { value: '28', label: '28' },
        { value: '29', label: '29' },
        { value: '30', label: '30' },
        { value: '31', label: '31' },
        { value: '32', label: '32' },
        { value: '33', label: '33' },
        { value: '34', label: '34' },
        { value: '35', label: '35' }
      ],
      cuotas: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' }
      ],
      cuotasDefault: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' }
      ],
      radioCard: 0
    };
  },
  computed: {
    ...mapGetters([
      'loader'
    ])
  },
  mounted () {
    this.card.transaction_amount = this.order.totalPrice + this.order.delivery;
    this.card.net_amount = this.order.totalPrice + this.order.delivery;
    this.getIdentification();
  },
  methods: {
    selectPayment () {
      this.$store.commit('setPaymentType', false);
    },
    validateBeforeCard () {
      this.errors.clear();
      const instance = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          if (!instance.doSubmit) {
            var $form = document.querySelector('#pay');
            window.Mercadopago.createToken($form, instance.sdkResponseHandler);
            return false;
          } else {
            instance.proccessCard();
          }
        }
      });
    },
    proccessCard () {
      this.$store.commit('setLoader', true);
      this.order.mp = 1;
      this.$http.post('marketplace/payment/card', { order: this.order, cardCredit: this.card }).then(({ data }) => {
        if (data.code === 705 || data.code === 706) {
          this.$emit('cancelTransactionCard', true);
        }
        if (data.code === 780) {
          this.$emit('pendingTransactionCard', true);
        }
        if (data.code === 200) {
          this.$store.commit('setPaymentType', false);
          this.$store.commit('setCart', []);
          this.$emit('approvedTransactionCard', data.orderId);
        }
        this.$store.commit('setLoader', false);
      }).catch(() => {
        this.$store.commit('setLoader', false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Ocurrio un error al procesar la petición con la pasarela de pago <br><strong style='color: #ffffff;'>Intenta realizar el pago de nuevo</strong> ",
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        });
      });
    },
    guessPaymentMethod (cardnumber) {
      const number = cardnumber.replace(' ', '');
      if (number.length >= 6) {
        const bin = number.substring(0, 6);
        window.Mercadopago.getPaymentMethod({
          bin: bin
        }, this.setPaymentMethod);
      }
    },
    setPaymentMethod (status, response) {
      if (status === 200) {
        const paymentMethodId = response[0].id;
        this.card.payment_method_id = paymentMethodId;
        this.getInstallments();
      } else {
        this.$buefy.dialog.alert({
          message: 'Error al obtener el método de pago',
          confirmText: 'Aceptar',
          onConfirm: () => {
            window.location.reload();
          }
        });
      }
    },
    getInstallments () {
      const paymentMethodId = this.card.payment_method_id;
      const transactionAmount = this.card.transaction_amount;
      const instance = this;
      window.Mercadopago.getInstallments({
        payment_method_id: paymentMethodId,
        amount: parseFloat(transactionAmount)
      }, function (status, response) {
        if (status === 200) {
          const data = [];
          response[0].payer_costs.forEach(installment => {
            const opt = {
              label: installment.recommended_message,
              value: installment.installments
            };
            data.push(opt);
          });
          instance.cuotas = data;
        } else {
          instance.$buefy.dialog.alert({
            message: 'Error al obtener las cuotas',
            confirmText: 'Aceptar',
            onConfirm: () => {
              window.location.reload();
            }
          });
        }
      });
    },
    getIdentification () {
      this.$http.get('marketplace/payment/identifications').then(({ data }) => {
        if (data.code === 200) {
          this.identification = data.data;
        }
      });
    },
    sdkResponseHandler (status, response) {
      if (status !== 200 && status !== 201) {
        this.$buefy.dialog.alert({
          message: 'Error al obtener el token',
          confirmText: 'Aceptar',
          onConfirm: () => {
            window.location.reload();
          }
        });
        this.doSubmit = false;
      } else {
        const t = document.getElementById('token');
        t.value = response.id;
        this.card.token = response.id;
        this.doSubmit = true;
        this.validateBeforeCard();
      }
    }
  },
  watch: {
    cardNumber () {
      if (this.cardNumber !== '') {
        this.guessPaymentMethod(this.cardNumber);
      } else {
        this.card.payment_method_id = null;
        this.cuotas = this.cuotasDefault;
      }
    }
  }
};
</script>
<style lang="scss">
#cardMercadoPago{
.paymentCard {
 padding: 0 15px;
 .field:not(:last-child) {
   margin-bottom: inherit;
}
  //new scss
  .arrow{
    position: absolute;
    left: -8px;
  }
  .cont{
        display: flex;
    flex-direction: column;
    padding: 0 15px;
    input{
      box-shadow: inherit;
    border-radius: 9px;
    height: 32px;
    font-size: 10px;
    font-family: segoe;
    padding: 0 10px;
    border: 1px solid rgba(3, 15, 52, 0.42);
    text-align: left;
    color: black;
    }
    select{
      box-shadow: inherit;
    border-radius: 9px;
    height: 32px;
    font-size: 10px;
    font-family: segoe;
    padding: 0 10px;
    border: 1px solid rgba(3, 15, 52, 0.42);
    text-align: left;
    }
    .columns{
      &.wrap{
        flex-wrap: wrap;
        display: flex;
      }
    }
    .column{
      &.border-bot{
            border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
      }
    }
  }
  .title-payment{
    width: calc(100% - 30px);
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
  }
    .pay-cont{
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
    }
    // end scss

  .furute-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
  }
  .back_method {
    color: rgb(0, 0, 0) !important;
    font-size: 14px;
    margin-top: 20px;
  }

  .terms-link {
    padding-left: 0;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
  }
  .header-paytitle {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    .img-paymns {
      display: flex;
      align-items: center;
      img {
        margin: 3px;
      }
    }
    .title-pymen {
      font-weight: bold;
      font-size: 20px;
      i {
        margin-right: 5px;
      }
    }
  }
  .title-type {
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 15px;
    margin-top: 5px;
  }
  .input,
  .taginput .taginput-container.is-focusable,
  .textarea {
    text-align: right;
  }

  .field-label.is-normal label.label {
    font-family: normal;
    font-size: 12px;
    text-align: left;
    font-weight: 100;
    height: 100%;
    padding-top: 5px;
  }
  .field-label.is-normal {
    padding-top: 0 !important;
    margin-right: 10px !important;
  }
  .hsy-dropdown > .list {
    width: 100% !important;
  }
  .hsy-dropdown > .selected {
    background-position: 96% center, center center !important;
    height: 33px !important;
    border: 1px solid #ccc !important;
  }
  .list {
    height: 20vh;
    overflow: scroll;
  }
  .img-card {
    height: 0.75rem;
    display: flex;
  }
  .img-card2 {
    height: 1rem;
    display: flex;
  }
  .inputTrans {
    background: transparent;
    border-radius: 5px;
    border: 1px solid lightgray;
    color: #000000;
    width: 100%;
    text-align: right;
    padding: 4px 10px;
    font-size: 14px;
    margin: 2px 0px;
  }
  .opacity06 {
    opacity: 0.6;
  }
  .btn{
    &.btn-reserva-disabled{
    height: 40px;
    background: var(--buttonBackground);
    color: var(--buttonTextColor) !important;
    .text{
          color: var(--buttonTextColor) !important;
    }
    width: 100%;
    max-width: 360px;
    border-radius: 6px;
    border: inherit;
    cursor: pointer;
    }
  }
  @media (min-width: 768px) {
    .m-left-10-mq {
      margin-left: -10px;
    }
    .check-top {
      margin-top: 60px;
    }
    .border-left {
      border-left: 1px solid lightgray;
    }
  }
  .selectedCard {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px;
  }
}

@media (max-width: 767px) {
  .metohpayment {
    background-color: white;
    padding: 30px 30px !important;
  }
  .verticals {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
    padding: 10px !important;
  }

  .vertical-line {
    background: #ddd !important;
    width: 50% !important;
    height: 2px !important;
  }

  .padding-top20 {
    padding-top: 40px;
    padding: 40px 20px 10px;
    text-align: center;
  }
  .texter {
    font-weight: 500;
    color: #999;
    i {
      margin-right: 5px;
    }
  }
  .vertical-o {
    background: #fff;
    width: auto;
    height: 20px;
    display: flex;
    line-height: 17px;
    padding: 0 30px;
  }
}
.bottom-text, .bottom-text-r {
  font-size: 15px;
  -webkit-box-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: flex;
  padding: 5px;
  justify-content: flex-end;
}
.bottom-text {
  -webkit-box-pack: start;
  justify-content: flex-start;
}
}
</style>
