<template>
    <div id="OrderStatus">
      <div class="cart-header">
        <b-button class="cart-header-btn-close" @click="$parent.close()"><i class="icon-mks icon_close black" style="height: 15px;width: 15px"></i></b-button>
      </div>
      <div class="cont">
            <div class="content" v-for="(product, pIndex) in order.products" :key="'cart-product#' + pIndex">
              <div class="cont-title-product">
                <span class="text bold segoeBold size-19" style="text-transform: uppercase;">
                  {{ shortText(product.name) }}
                    <!-- <img src="@/assets/icons/2x1.svg" v-show="isDeals(product.deal, '2x1')" :style="{right: (product.specs) ? '30px' : '10px'}">
                    <img src="@/assets/icons/3x2.svg" v-show="isDeals(product.deal, '3x2')" :style="{right: (product.specs) ? '30px' : '10px'}"> -->
                </span>
                <div class="itemCart">
                  <span class="cartPrice">
                    <span class="text bold size-16" style="white-space: nowrap;" :class="{'textLine': product.percentOff}">{{ product.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
                    <span class="cartPrice-discountPercent" v-if="product.percentOff">-{{ product.percentOff }}%</span>
                  </span>
                  <span class="text bold size-16" style="white-space: nowrap;" v-if="product.percentOff">{{ getTotalProduct(product) | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
                </div>
              </div>
              <div class="is-white-flex flex-col" v-if="product.options">
                <div v-for="(adi, iadi) in product.options.products" :key="'adition' + iadi">
                  <span class="text bold size-16">{{ shortText(adi.name) }}</span>
                  <div class="addons-cont" v-for="(pItem, io) in adi.items" :key="'itemProd' + io">
                    <span  class="text segoe size-12" >{{ pItem.item }} x 1</span>
                    <span class="text segoe size-12">{{ pItem.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
                  </div>
                </div>
              </div>
              <div class="is-white-flex">
                <span class="text segoe size-12 ellip-desc">{{product.description}}</span>
              </div>
            </div>
            <div class="table-zone">
                <div class="zone"> <span class="text bold size-13"> {{order.zone}}</span></div>
                <div class="table"> <span class="text bold size-13"> {{order.table}}</span></div>
            </div>
            <div class="center-div">
                <img src="@/assets/image/processMenu.svg" style="margin-bottom:10px" alt="">
                <div class="text bold size-18 margin-bot-10"><strong id="cartEmpty">Estamos alistando tu orden</strong></div>
                <div class="text size-12 center" style="padding: 0 50px;">Espera en tu mesa, pronto llevaremos tu pedido.</div>
            </div>
            <div class="cont-btn-menu">
                <div class="btn-menu"  @click="$parent.close()"><span class="text bold size-18">Ir al menú</span></div>
            </div>
      </div>
      <TransactionApproved v-model="showApproved" v-if="showApproved" :idOrder="orderId"></TransactionApproved>
    </div>
</template>
<script>
import TransactionApproved from '@/components/transactionStatus/TransactionApproved';
export default {
  components: { TransactionApproved },
  props: ['order', 'orderId', 'showApproved'],
  data () {
    return {
    };
  },
  methods: {
    shortText (text) {
      if (text) {
        if (text.length > 25) {
          return text.substr(0, 25) + '...';
        } else {
          return text;
        }
      } else {
        return 'N/A';
      }
    },
    getTotalProduct (product) {
      let priceComplete = product.price;
      if (product.percentOff) {
        priceComplete = product.price - (product.price * product.percentOff / 100);
      }
      let total = priceComplete * product.quantity;
      if (product.options.extra > 0) {
        total += product.options.extra;
      }
      return total;
    },
    isDeals (data, type) {
      let show = false;
      Object.values(data).map(item => {
        if (item.type === type) {
          show = true;
        }
      });
      return show;
    }
  }
};
</script>
<style lang="scss">
#OrderStatus{
.cart-header{
        padding: 5px;
        height: 40px;
        .cart-header-btn-close{
            border: inherit;
            float: right;
        }
}
.cont{
        padding: 10px 30px;

    .content{
        .cont-title-product{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
        }
    }
    .table-zone{
        display: flex;
        .zone{
            padding: 5px 10px;
            background: #CDD500;
            border-radius: 4px;
            margin-right: 5px;
        }
        .table{
            padding: 5px 10px;
            background: #CDD500;
            border-radius: 4px;

        }
    }
    .center-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px 0;
    }
    .cont-btn-menu{
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
        .btn-menu{
            background: var(--buttonBackground);
            color: var(--buttonTextColor) !important;
            .text{
                  color: var(--buttonTextColor) !important;
            }
            width: calc(100% - 50px);
            height: 45px;
            max-width: 350px;
            border-radius: 6px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
}
</style>
