<template>
  <div id="cardPayU">
    <div class="container paymentCard">
      <!-- <div class="cart-header">
        <b-button icon-left="times" style="border: inherit;float: right;" class="cart-header-btn-close" @click="$parent.close()"></b-button>
      </div> -->
      <!-- <div class="title-payment">
        <div class="arrow" @click="$parent.close()">
           <i style="color:black" class="fas fa-arrow-left"></i>
        </div>
        <span class="text bold segoe size-23 margin-bot-10">Finaliza tu pedido</span>
      </div> -->
      <form class="cont" id="form-payments" @submit.prevent="validateBeforeCard">
        <div class="columns">
          <div class="column">
            <!-- <div class="pay-cont">
                <span class="text bold segoe size-16"><i class="fas fa-credit-card"></i>  Tarjeta de crédito</span>
                <b-radio v-model="radioCard"
                native-value="0">
                </b-radio>
            </div>
            <div class="pay-cont">
                <span class="text bold segoe size-16"> <i class="far fa-credit-card"></i>  Tarjeta débito</span>
                <b-radio disabled v-model="radioCard"
                    native-value="1">
                </b-radio>
            </div> -->
            <b-field label="Titular de la tarjeta" :type="{'is-danger': errors.has('name')}" :message="errors.first('name')">
              <b-input type="text"
                  name="name"
                  id="name"
                  v-model="card.name"
                  placeholder="Nombre del titular"
                  v-validate="'required|min:2'"
                  autofocus icon-pack="fa" icon=""></b-input>
            </b-field>
            <b-field label="Identificación: " :type="{'is-danger': errors.has('identification')}" :message="errors.first('identification')">
              <b-input type="text"
                  name="identification"
                  id="identification"
                  v-model="card.identification"
                  placeholder="Documento de identidad"
                  v-validate="'required|numeric'"  autofocus icon-pack="fa" icon=""></b-input>
            </b-field>
            <b-field label="Teléfono:" :type="{'is-danger': errors.has('phones')}" :message="errors.first('phones')">
                <b-input type="text"
                  name="phones"
                  id="phones"
                  v-model="card.phone"
                  placeholder="Celular"
                  v-cleave="masks.custom"
                  v-validate="'required|min:10|max:10|numeric'" autofocus icon-pack="fa" icon=""></b-input>
            </b-field>
            <b-field label="Dirección:" :type="{'is-danger': errors.has('address')}" :message="errors.first('address')">
                <b-input   type="text"
                  name="address"
                  id="address"
                  v-model="card.address"
                  placeholder="Dirección"
                  v-validate="'required|min:6'" autofocus icon-pack="fa" icon=""></b-input>
            </b-field>
            <b-field label="Ciudad:" :type="{'is-danger': errors.has('city')}" :message="errors.first('city')">
              <b-input  type="text"
                  name="city"
                  id="city"
                  v-model="card.city"
                  placeholder="Ciudad"
                  v-validate="'required|min:3'" autofocus icon-pack="fa" icon=""></b-input>
            </b-field>
          </div>
          <div class="column border-left">
            <span class="text bold segoe size-11">Datos tarjeta</span>
            <b-field label="Número de la tarjeta:" :type="{'is-danger': errors.has('card')}" :message="errors.first('card')">
              <b-input type="text"
                  name="card"
                  id="card"
                  v-model="card.card"
                  v-cleave="masks.creditCard"
                  placeholder="xxxx xxxx xxxx xxxx"
                  v-validate="'required|credit_card'"
                  expanded autofocus icon-pack="fa" icon=""></b-input>
            </b-field>
            <div class="columns wrap">
              <b-field label="Mes:" class="column" :type="{'is-danger': errors.has('mes')}" :message="errors.first('mes')">
                <b-select name="mes" v-model="card.mes" placeholder="Mes"  id="mes" v-validate="'required'" expanded>
                  <option v-for="(me, m) in mes" :value="me.value" :key="'me#' + m">{{ me.label }}</option>
                </b-select>
              </b-field>
              <b-field label="Año:" class="column" :type="{'is-danger': errors.has('ano')}" :message="errors.first('ano')">
                <b-select name="ano" v-model="card.ano" placeholder="Año"  id="ano" v-validate="'required'" expanded>
                  <option v-for="(an, a) in ano" :value="an.value" :key="'an#' + a">{{ an.label }}</option>
                </b-select>
              </b-field>
              <b-field label="Cuotas:" class="column" :type="{'is-danger': errors.has('cuota')}" :message="errors.first('cuota')">
                <b-select name="cuota" v-model="card.cuota" placeholder="Cuotas"  id="cuota" v-validate="'required'" expanded>
                  <option v-for="(cuo, c) in cuota" :value="cuo.value" :key="'cuo#' + c">{{ cuo.label }}</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <div class="terms pay">
          <div class="copy-terms">
            <b-checkbox class="terms-label" v-model="authTerms"></b-checkbox>&nbsp;<span class="text segoe"> Al dar clic en "Pagar" declara que conoce y acepta el tratamiento de datos personales previamente informado y al enviar este formato a Precompro acepta nuestros</span>
            <a @click="showTerms = true" class="link-terms">Términos y condiciones y la Política de Privacidad*</a>.
          <span class="text segoe"> Para cumplir con el objeto social de Precompro, al hacer la reserva se compartirá su información con el restaurante aliado. </span>
          </div>
        </div>
        <div class="columns">
          <div class="column bottom-text">
          </div>
          <div class="column bottom-text-r padding-500"
            style="display: flex;justify-content: flex-end;">
            <span class="text segoe">Pagos seguros a través de </span>&nbsp;<img src="@/assets/image/payu-color.png" style="width: 2.5rem; margin-left: 5px;">
          </div>
        </div>
        <div id="modal-terms">
          <b-modal :active.sync="showTerms">
            <div class="terms-body">
              <h3 class="title-modal">Política Tratamiento de Datos Personales de Click2buy S.A.S.</h3>
              <terms :fecha="'22 de Noviembre del 2019'"></terms>
            </div>
          </b-modal>
        </div>
        <div class="row">
          <div class="col-12" style="padding-bottom: 30px;text-align: center;">
            <button type="submit"
              class="btn btn-reserva-disabled"
              :disabled="(loader || !authTerms)"
              :class="{'opacity06':(card.name === null || card.name === ''||
                card.identification === null || card.identification === '' ||
                card.phone === null || card.phone === '' || card.address === null || card.address === '' ||
                card.city === null || card.city === '' || card.card === null || card.card === '' ||
                card.mes === null || card.mes === '' ||
                card.ano === null || card.ano === '' || card.cuota === null || card.cuota === '' || !authTerms || (errors.items.length > 0))}">
                <span class="text size-18 family-bold">Realizar pago</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import terms from '@/components/Terms';
import { mapGetters } from 'vuex';
import Cleave from 'cleave.js';
const cleave = {
  name: 'cleave',
  bind (el, binding) {
    const input = el.querySelector('input');
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind (el) {
    const input = el.querySelector('input');
    input._vCleave.destroy();
  }
};
export default {
  components: { terms },
  props: ['order'],
  directives: { cleave },
  data () {
    return {
      masks: {
        creditCard: { creditCard: true },
        custom: {
          numericOnly: true
        }
      },
      authTerms: true,
      showTerms: false,
      card: {
        name: null,
        identification: null,
        phone: null,
        address: null,
        city: null,
        card: null,
        mes: null,
        ano: null,
        cuota: null
      },
      mes: [
        { value: '01', label: '01' },
        { value: '02', label: '02' },
        { value: '03', label: '03' },
        { value: '04', label: '04' },
        { value: '05', label: '05' },
        { value: '06', label: '06' },
        { value: '07', label: '07' },
        { value: '08', label: '08' },
        { value: '09', label: '09' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
      ],
      ano: [
        { value: '2020', label: '2020' },
        { value: '2021', label: '2021' },
        { value: '2022', label: '2022' },
        { value: '2023', label: '2023' },
        { value: '2024', label: '2024' },
        { value: '2025', label: '2025' },
        { value: '2026', label: '2026' },
        { value: '2027', label: '2027' },
        { value: '2028', label: '2028' },
        { value: '2028', label: '2028' },
        { value: '2029', label: '2029' },
        { value: '2030', label: '2030' },
        { value: '2031', label: '2031' },
        { value: '2032', label: '2032' },
        { value: '2033', label: '2033' },
        { value: '2034', label: '2034' },
        { value: '2035', label: '2035' }
      ],
      cuota: [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
        { value: '13', label: '13' },
        { value: '14', label: '14' },
        { value: '15', label: '15' },
        { value: '16', label: '16' },
        { value: '17', label: '17' },
        { value: '18', label: '18' },
        { value: '19', label: '19' },
        { value: '20', label: '20' },
        { value: '21', label: '21' },
        { value: '22', label: '22' },
        { value: '23', label: '23' },
        { value: '24', label: '24' },
        { value: '25', label: '25' },
        { value: '26', label: '26' },
        { value: '27', label: '27' },
        { value: '28', label: '28' },
        { value: '29', label: '29' },
        { value: '30', label: '30' },
        { value: '31', label: '31' },
        { value: '32', label: '32' },
        { value: '33', label: '33' },
        { value: '34', label: '34' },
        { value: '34', label: '34' },
        { value: '35', label: '35' },
        { value: '35', label: '35' },
        { value: '36', label: '36' }
      ],
      radioCard: 0
    };
  },
  computed: {
    ...mapGetters([
      'loader'
    ])
  },
  methods: {
    selectPayment () {
      this.$store.commit('setPaymentType', false);
    },
    validateBeforeCard () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.card.expiration = this.card.ano + '/' + this.card.mes;
          this.proccessCard();
        }
      });
    },
    proccessCard () {
      this.$store.commit('setLoader', true);
      this.$http.post('marketplace/payment/card', { order: this.order, cardCredit: this.card }).then(({ data }) => {
        if (data.code === 705) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Transacción Declinada <br><strong style='color: #ffffff;'>" + data.msg + '</strong> ',
            type: 'is-danger',
            position: 'is-bottom',
            queue: false
          });
        }
        if (data.code === 701 || data.code === 702 || data.code === 703 || data.code === 704) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Transacción Declinada <br><strong style='color: #ffffff;'> " + data.msg + ' </strong> ',
            type: 'is-danger',
            position: 'is-bottom',
            queue: false
          });
        }
        if (data.code === 710 || data.code === 711) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Ocurrio un error <br><strong style='color: #ffffff;'>Intenta realizar la reserva de nuevo</strong> ",
            type: 'is-danger',
            position: 'is-bottom',
            queue: false
          });
        }
        if (data.code === 780) {
          this.$emit('pendingTransactionCard', true);
        }
        if (data.code === 200) {
          this.$store.commit('setPaymentType', false);
          this.$store.commit('setCart', []);
          this.$emit('approvedTransactionCard', data.orderId);
          this.$parent.close();
        }
        this.$store.commit('setLoader', false);
      }).catch(() => {
        this.$store.commit('setLoader', false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Ocurrio un error al procesar la petición con la pasarela de pago <br><strong style='color: #ffffff;'>Intenta realizar el pago de nuevo</strong> ",
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        });
      });
    }
  }
};
</script>
<style lang="scss">
#cardPayU{
  width: 100%;
.paymentCard {
  padding: 0 15px;
  //new scss
  .arrow{
    position: absolute;
    left: -8px;
  }
  .cont{
        display: flex;
    flex-direction: column;
    padding: 0 15px;
    input{
      box-shadow: inherit;
    border-radius: 9px;
    height: 32px;
    font-size: 10px;
    font-family: segoe;
    padding: 0 10px;
    border: 1px solid rgba(3, 15, 52, 0.42);
    text-align: left;
    color: black;
    }
    select{
      box-shadow: inherit;
    border-radius: 9px;
    height: 32px;
    font-size: 10px;
    font-family: segoe;
    padding: 0 10px;
    border: 1px solid rgba(3, 15, 52, 0.42);
    text-align: left;
    color: black;
    }
    .columns{
      &.wrap{
        flex-wrap: wrap;
        display: flex;
      }
    }
    .column{
      &.border-bot{
            border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
      }
    }
  }
  .title-payment{
    width: calc(100% - 30px);
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
  }
    .pay-cont{
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
    }
    // end scss
  .furute-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
  }
  .back_method {
    color: rgb(0, 0, 0) !important;
    font-size: 14px;
    margin-top: 20px;
  }

  .terms-link {
    padding-left: 0;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
  }
  .header-paytitle {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    .img-paymns {
      display: flex;
      align-items: center;
      img {
        margin: 3px;
      }
    }
    .title-pymen {
      font-weight: bold;
      font-size: 20px;
      i {
        margin-right: 5px;
      }
    }
  }
  .title-type {
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 15px;
    margin-top: 5px;
  }
  .input,
  .taginput .taginput-container.is-focusable,
  .textarea {
    text-align: right;
  }

  .field-label.is-normal label.label {
    font-family: normal;
    font-size: 12px;
    text-align: left;
    font-weight: 100;
    height: 100%;
    padding-top: 5px;
  }
  .field-label.is-normal {
    padding-top: 0 !important;
    margin-right: 10px !important;
  }
  .hsy-dropdown > .list {
    width: 100% !important;
  }
  .hsy-dropdown > .selected {
    background-position: 96% center, center center !important;
    height: 33px !important;
    border: 1px solid #ccc !important;
  }
  .list {
    height: 20vh;
    overflow: scroll;
  }
  .btn{
    &.btn-reserva-disabled{
    height: 40px;
    background: var(--buttonBackground);
    color: var(--buttonTextColor) !important;
    .text{
          color: var(--buttonTextColor) !important;
    }
    width: 230px;
    border-radius: 6px;
    border: inherit;
    cursor: pointer;
    }
  }
  .img-card {
    display: flex;
    height: 0.75rem;
  }
  .img-card2 {
    height: 1.5rem;
  }
  .inputTrans {
    background: transparent;
    border-radius: 5px;
    border: 1px solid lightgray;
    color: #000000;
    width: 100%;
    text-align: right;
    padding: 4px 10px;
    font-size: 14px;
    margin: 2px 0px;
  }
  .opacity06 {
    opacity: 0.6;
    box-shadow: inherit;
  }
.select:not(.is-multiple):not(.is-loading)::after {
    border-color: black;
}
.select select:active{
    border-color: rgba(170, 170, 170, 0.5);
    box-shadow: 0 0 0 0.125em rgba(0, 0, 0, 0.25);
}
.select select:focus{
    border-color: rgba(170, 170, 170, 0.5);
    box-shadow: 0 0 0 0.125em rgba(0, 0, 0, 0.25);
}
.input:focus,.input:focus{
      border-color: rgba(170, 170, 170, 0.5);
    box-shadow: 0 0 0 0.125em rgba(0, 0, 0, 0.25);
}
.b-checkbox.checkbox input[type=checkbox]:checked + .check {
    background-color: #000 ;
    border-color: #000;
}
a{
  color: #000;
}
.terms-body{
  background: white;
  .title-modal{
    text-align: center;
    padding: 10px;

  }
}
    .cart-header{
        padding: 5px;
        height: 40px;
    }
    .input:active{
      border: inherit;
      box-shadow: inherit;
    }
  @media (min-width: 768px) {
    .m-left-10-mq {
      margin-left: -10px;
    }
    .check-top {
      margin-top: 60px;
    }
    .border-left {
      border-left: 1px solid lightgray;
    }
  }
}

@media (max-width: 767px) {
  .metohpayment {
    background-color: white;
    padding: 30px 30px !important;
  }
  .verticals {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
    padding: 10px !important;
  }

  .vertical-line {
    background: #ddd !important;
    width: 50% !important;
    height: 2px !important;
  }

  .padding-top20 {
    padding-top: 40px;
    padding: 40px 20px 10px;
    text-align: center;
  }
  .texter {
    font-weight: 500;
    color: #999;
    i {
      margin-right: 5px;
    }
  }
  .vertical-o {
    background: #fff;
    width: auto;
    height: 20px;
    display: flex;
    line-height: 17px;
    padding: 0 30px;
  }
}
}
</style>
