<template>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 1366 768" :style="'enable-background:new 0 0 1366 768; width:'+width" xml:space="preserve">
  <g>
    <path :style="{fill: fill}" d="M707.6,384l74.9-74.8c6.8-6.8,6.8-17.8,0-24.6c-3.3-3.3-7.7-5.1-12.3-5.1c-4.6,0-9,1.8-12.3,5.1L683,359.4
      l-74.8-74.8c-3.3-3.3-7.7-5.1-12.3-5.1c-4.6,0-9,1.8-12.3,5.1c-3.3,3.3-5.1,7.7-5.1,12.3c0,4.6,1.8,9,5.1,12.3l74.8,74.8
      l-74.8,74.8c-3.3,3.3-5.1,7.7-5.1,12.3c0,4.6,1.8,9,5.1,12.3c3.3,3.3,7.7,5.1,12.2,5.1c4.6,0,9.1-1.9,12.2-5.1l74.8-74.8l74.8,74.8
      c3.3,3.3,7.7,5.1,12.2,5.1c4.7,0,9.1-1.8,12.2-5.1c3.3-3.3,5.1-7.7,5.1-12.3c0-4.6-1.8-9-5.1-12.3L707.6,384z"/>
    <path :style="{fill: fill}" d="M836.8,230.2c-41.1-41.1-95.7-63.7-153.8-63.7c-58.1,0-112.7,22.6-153.8,63.7c-41.1,41.1-63.7,95.7-63.7,153.8
      c0,58.1,22.6,112.7,63.7,153.8c41.1,41.1,95.7,63.7,153.8,63.7c58.1,0,112.7-22.6,153.8-63.7c41.1-41.1,63.7-95.7,63.7-153.8
      C900.6,325.9,877.9,271.3,836.8,230.2L836.8,230.2z M812.6,513.6c-34.6,34.6-80.7,53.7-129.6,53.7c-48.9,0-95-19.1-129.6-53.7
      c-71.5-71.5-71.5-187.8,0-259.2c34.6-34.6,80.7-53.7,129.6-53.7c48.9,0,95,19.1,129.6,53.7C884.1,325.9,884.1,442.1,812.6,513.6
      L812.6,513.6z M819.3,520.3"/>
  </g>
  </svg>
</template>
<script>
export default {
  props: ['fill', 'width']
};
</script>
