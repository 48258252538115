<template>
    <div class="container paymentCard">
       <!-- <div class="cart-header">
        <b-button icon-left="times" style="border: inherit;float: right;" class="cart-header-btn-close" @click="$parent.close()"></b-button>
      </div> -->
      <!-- <div class="title-payment">
        <div class="arrow" @click="$parent.close()">
           <i style="color:black" class="fas fa-arrow-left"></i>
        </div>
        <span class="text bold segoe size-23 margin-bot-10">Finaliza tu pedido</span>
      </div> -->
      <form class="cont" @submit.prevent="validateBeforePse">
        <div class="columns">
          <div class="column">
            <!-- <div class="pay-cont">
                <span class="text bold segoe size-16"><i class="fas fa-credit-card"></i>  Tarjeta de crédito</span>
                <b-radio disabled v-model="radioCard"
                native-value="0">
                </b-radio>
            </div>
            <div class="pay-cont">
                <span class="text bold segoe size-16"> <i class="far fa-credit-card"></i>  Tarjeta débito</span>
                <b-radio v-model="radioCard"
                    native-value="1">
                </b-radio>
            </div> -->
              <b-field label="Banco:" :type="{'is-danger': errors.has('bank')}" :message="errors.first('bank')">
              <b-select name="bank" v-model="pse.bank" placeholder="-- Seleccione --"  id="bank" v-validate="'required'" expanded>
                <option v-for="(bank, b) in bankList" :value="bank.value" :key="'bank#' + b">{{ bank.label }}</option>
              </b-select>
            </b-field>
            <b-field label="Titular de la tarjeta:" :type="{'is-danger': errors.has('name')}" :message="errors.first('name')">
              <b-input  type="text"
                name="name"
                id="name"
                placeholder="Nombre del titular"
                v-model="pse.name"
                v-validate="'required|alpha_spaces'" expanded autofocus icon-pack="fa"></b-input>
            </b-field>
            <b-field label="Email:" :type="{'is-danger': errors.has('email')}" :message="errors.first('email')">
              <b-input type="text"
                name="email"
                id="email"
                placeholder="Email"
                v-model="pse.email"
                v-validate="'required|email'" expanded autofocus icon-pack="fa"></b-input>
            </b-field>
            <div style="display:flex">
            <b-field  label="Tipo de id*" style="min-width: 100px;width:100px;margin-right: 10px;" :type="{'is-danger': errors.has('typeDoc')}" :message="errors.first('typeDoc')">
              <b-select name="typeDoc" v-model="pse.typeDocument" placeholder="-- Seleccione --"  id="typeDoc" v-validate="'required'" expanded>
                <option v-for="(person, b) in typePerson" :value="person.value" :key="'person#' + b">{{ person.label }}</option>
              </b-select>
            </b-field>
            <b-field label="Identificación*" style="width: 100%;" :type="{'is-danger': errors.has('identification')}" :message="errors.first('identification')">
              <b-input  type="text"
                name="identification"
                id="identification"
                placeholder="Documento de identidad"
                v-model="pse.identification"
                v-validate="'required|numeric'" expanded autofocus icon-pack="fa"></b-input>
            </b-field>
            </div>
            <b-field label="Teléfono:" :type="{'is-danger': errors.has('phone')}" :message="errors.first('phone')">
              <b-input  type="text"
                name="phone"
                id="phone"
                placeholder="Celular"
                v-model="pse.phone"
                v-validate="'required|min:10|max:10'" expanded autofocus icon-pack="fa"></b-input>
            </b-field>
            <b-radio
              type="radio"
              name="typePeople"
              native-value="N"
              v-model="pse.typePeople"
              v-validate="'required|included:J,N'">
               <span class="text segoe size-16">Persona Natural</span>
            </b-radio>
            <b-radio
              type="radio"
              name="typePeople"
              native-value="J"
              v-model="pse.typePeople">
              <span class="text segoe size-16">Persona Juridica</span>
            </b-radio>
            <span v-show="errors.has('typePeople')" class="text-danger">
              {{ errors.first('typePeople') }}
            </span>
          </div>
        </div>
        <div class="terms pay">
          <div class="copy-terms">
            <b-checkbox class="terms-label" v-model="authTerms"></b-checkbox>&nbsp; <span class="text segoe">Al dar clic en "Pagar" declara que conoce y acepta el tratamiento de datos personales previamente informado y al enviar este formato a Precompro acepta nuestros </span>
            <a @click="showTerms = true" class="link-terms">Términos y condiciones y la Política de Privacidad*</a>.
          <span class="text segoe">  Para cumplir con el objeto social de Precompro, al hacer la reserva se compartirá su información con el restaurante aliado. </span>
          </div>
        </div>
        <div class="columns">
          <div class="column bottom-text">
          </div>
          <div class="column bottom-text-r padding-500"
            style="display: flex;justify-content: flex-end;">
            <span class="text segoe">Pagos seguros a través de</span> &nbsp;<img src="@/assets/image/payu-color.png" style="width: 2.5rem; margin-left:5px">
          </div>
        </div>
        <div id="modal-terms">
          <b-modal :active.sync="showTerms">
            <div class="terms-body">
              <h3 class="title-modal">Política Tratamiento de Datos Personales de Click2buy S.A.S.</h3>
              <terms :fecha="'22 de Noviembre del 2019'"></terms>
            </div>
          </b-modal>
        </div>
        <div class="btn-pay-content">
          <button type="submit"
            class="btn-pay"
            :disabled="!authTerms"
            :class="{'opacity06':(pse.name === null || pse.name === ''||
              pse.identification === null || pse.identification === '' ||
              pse.phone === null || pse.phone === '' ||
              pse.email === null || pse.email === '' ||
              pse.bank === null || pse.bank === '' ||
              pse.typeDocument === null || pse.typeDocument === '' ||
              pse.typePeople === null || pse.typePeople === '' )}">
              <span class="text size-18 family-bold">Realizar pago</span>
          </button>
        </div>
      </form>
    </div>
</template>
<script>
import terms from '@/components/Terms';
import _ from 'lodash';

export default {
  components: { terms },
  props: ['order'],
  data () {
    return {
      bank: [{ value: 0, label: 'Seleccione una entidad bancaria' }],
      typePerson: [
        { value: 'CC', label: 'CC - Cédula de ciudadanía' },
        { value: 'CE', label: 'CE - Cédula de extranjería' },
        { value: 'NIT', label: 'NIT - En caso de ser una empresa' },
        { value: 'TI', label: 'TI - Tarjeta de Identidad' },
        { value: 'PP', label: 'PP - Pasaporte' },
        { value: 'CEL', label: 'CEL - En caso de identificarse a través de la línea del móvil' },
        { value: 'RC', label: 'RC - Registro civil de nacimiento' },
        { value: 'DE', label: 'DE - Documento de identificación extranjero' }
      ],
      pse: {
        bank: null,
        name: null,
        email: null,
        typeDocument: null,
        identification: null,
        phone: null,
        typePeople: null,
        url: null
      },
      authTerms: true,
      showTerms: false,
      bankList: [],
      radioCard: 1
    };
  },
  methods: {
    selectPayment () {
      this.$store.commit('setPaymentType', false);
    },
    validateBeforePse () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.proccessPse();
        }
      });
    },
    proccessPse () {
      this.$store.commit('setLoader', true);
      this.$http.post('marketplace/payment/pse', { order: this.order, pse: this.pse }).then(response => {
        if (response.data.transactionResponse.state === 'PENDING') {
          window.location.href = response.data.transactionResponse.extraParameters.BANK_URL;
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Operación fallida <br><strong style='color: #ffffff;'>Ocurrió un error al intentar realizar el pago, por favor intenta de nuevo</strong> ",
            type: 'is-danger',
            position: 'is-bottom',
            queue: false
          });
          this.$store.commit('setLoader', false);
        }
      }).catch(() => {
        this.$store.commit('setLoader', false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Ocurrio un error al procesar la petición con la pasarela de pago <br><strong style='color: #ffffff;'>Intenta realizar el pago de nuevo</strong> ",
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        });
      });
    },
    loadingBanks () {
      this.$store.commit('setGlobalLoader', true);
      this.$http.get('marketplace/payment/banks').then(({ data }) => {
        const aux = [];
        _.forEach(data.data, function (info) {
          aux.push({ value: info.pseCode, label: info.description });
        });
        this.bankList = aux;
        this.$store.commit('setGlobalLoader', false);
      }).catch(() => {
        this.$store.commit('setGlobalLoader', false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Operación fallida <br><strong style='color: #ffffff;'>Ocurrió al conectarnos con el proveedor de pagos</strong> ",
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        });
      });
    }
  },
  mounted () {
    this.pse.url = window.location.href;
    this.loadingBanks();
  }
};
</script>
<style lang="scss">
.container{
  //new scss
  .arrow{
    position: absolute;
    left: -8px;
  }
  .cont{
        display: flex;
    flex-direction: column;
    padding: 0 15px;
    input{
      box-shadow: inherit;
    border-radius: 9px;
    height: 32px;
    font-size: 10px;
    font-family: segoe;
    padding: 0 10px;
    border: 1px solid rgba(3, 15, 52, 0.42);
    text-align: left;
    color: black;
    }
    select{
      box-shadow: inherit;
    border-radius: 9px;
    height: 32px;
    font-size: 10px;
    font-family: segoe;
    padding: 0 10px;
    border: 1px solid rgba(3, 15, 52, 0.42);
    text-align: left;
    color: black;
    }
    .columns{
      &.wrap{
        flex-wrap: wrap;
        display: flex;
      }
    }
    .column{
      &.border-bot{
            border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
      }
    }
  }
  .title-payment{
    width: calc(100% - 30px);
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
  }
    .pay-cont{
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
    }
    // end scss
  &.paymentCard{
    .select:not(.is-multiple):not(.is-loading)::after {
    border-color: black;
}
.select select:active{
    border-color: rgba(170, 170, 170, 0.5);
    box-shadow: 0 0 0 0.125em rgba(0, 0, 0, 0.25);
}
.select select:focus{
    border-color: rgba(170, 170, 170, 0.5);
    box-shadow: 0 0 0 0.125em rgba(0, 0, 0, 0.25);
}
.input:focus,.input:focus{
      border-color: rgba(170, 170, 170, 0.5);
    box-shadow: 0 0 0 0.125em rgba(0, 0, 0, 0.25);
}
.b-checkbox.checkbox input[type=checkbox]:checked + .check {
    background-color: #000 ;
    border-color: #000;
}
a{
  color: #000;
}
.b-radio.radio input[type=radio]:checked + .check {
    border-color: #000;
}
.b-radio.radio input[type=radio] + .check:before {
      background-color: #000;
}
.b-radio.radio:hover input[type=radio]:not(:disabled) + .check {
    border-color: #000;
}
.terms-body{
  background: white;
}
  }
}
.hsy-dropdown > .list {
  width: 100% !important;
}
.text-danger {
  width: 100%;
  float: left;
  font-size: 12px;
  color: #ff3860;
}

.rigth-border {
  border-right: 1px solid #ccc !important;
}
.btn-pay {
    height: 40px;
    background: var(--buttonBackground);
    color: var(--buttonTextColor) !important;
    .text{
          color: var(--buttonTextColor) !important;
    }
    width: 230px;
    border-radius: 6px;
    width: 230px;
    border: inherit;
  cursor: pointer;
}
.btn-pay:disabled {
  background-color: rgba(181, 179, 179, 0.34) !important;
  color: #fff !important;
  border: 1px solid #ddd !important;
}
.btn-pay-content {
  width: 100%;
  position: relative;
  button {
    width: 100%;
    padding: 10px;
    margin: 20px 0;
  }
}
.list {
  height: 20vh;
  overflow: scroll;
}

.opacity06 {
  opacity: 0.6;
}
.img-card {
  height: 9rem;
}

select option {
  padding: 15px 0px;
}

@media (min-width: 768px) {
  .border-left {
    border-left: 1px solid lightgray;
  }
  // select {
  //   text-align-last: right;
  // }
  option {
    // direction: rtl;
    font-size: 12px;
  }
  .inputTrans {
    text-align: right;
  }
}
.v-select .dropdown-toggle {
  height: 32px;
}
</style>
