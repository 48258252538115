<template>
  <section ref="styles" style="display:none !important;" >
    :root{
        --toolbarBackground: {{ vendor.toolbarColor }};
        --footerBackground: {{ vendor.footerColor }};
        --footerTextColor: {{ vendor.footerTextColor }};
        --buttonBackground: {{ vendor.buttonColor }};
        --buttonTextColor: {{ vendor.buttonTextColor }};
        --mapTextColor: {{vendor.textColorMap}};
        --mapCircleColor: {{vendor.circleColorMap}};
        --backCardProduct: {{vendor.backCardProduct}};
        --textColorDescriptionCard: {{vendor.textColorDescriptionCard}};
        --textColorTitleCard: {{vendor.textColorTitleCard}};
        --textColorPriceCard: {{vendor.textColorPriceCard}};
    }
  </section>
</template>

<script>
export default {
  name: 'CustomVars',
  props: ['vendor'],
  mounted: function () {
    const styleTag = document.createElement('style');
    styleTag.textContent = this.$refs.styles.textContent;
    this.$refs.styles.textContent = null;
    this.$refs.styles.appendChild(styleTag);
  }
};
</script>
