<template>
  <div id="app">
    <CustomVar :vendor="config" v-if="$route.path == '/'"></CustomVar>
    <b-loading :is-full-page="true" :active.sync="globalLoader" :can-cancel="false" id="loadingGlobal">
      <b-icon icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>
    <b-loading :is-full-page="true" :active.sync="loader" :can-cancel="false">
      <b-icon icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>
    <div id="view" class="view-cont" :class="{notPadding: $route.path.split('/')[2] == 'menu-digital'}">
     <router-view class="view" :searchProducts="query" :search="search" :findVendor="find" @notLogo="embedded = $event"/>
    </div>
  </div>
</template>

<script>
import ProductView from '@/components/Product';
import popModeMenu from '@/components/modeCarta/popModeMenu';
import MiOrder from '@/components/modeCarta/MiOrder';
import { mapGetters } from 'vuex';
import CustomVar from '@/components/CustomVars';
import LCrypt from 'lcrypt';
const lcrypt = new LCrypt(process.env.VUE_APP_KEY);
export default {
  components: { CustomVar },
  data () {
    return {
      errorImg: false,
      showCartRigth: false,
      cartList: [],
      search: false,
      query: '',
      embedded: false,
      find: '',
      uuid: null,
      lang: 'es',
      config: {
        toolbarColor: '#ffffff',
        footerColor: '#151E26',
        footerTextColor: '#ffffff',
        buttonColor: '#559A70',
        buttonTextColor: '#ffffff'
      }
    };
  },
  computed: {
    ...mapGetters([
      'getCart',
      'getVendor',
      'getProductInfo',
      'loader',
      'globalLoader'
    ])
  },
  beforeMount () {
    if (window.innerWidth > 600) {
      this.$router.push('/wrong-device');
    }
  },
  methods: {
    errorImgVendor () {
      this.errorImg = true;
    },
    closeNotification () {
      this.showCartRigth = false;
    },
    closeNotification2 (event) {
      if (event.target.id !== 'cartShow') {
        this.showCartRigth = false;
      }
    },
    showNotification () {
      if (this.getVendor.showMenu === 1) {
        this.$buefy.modal.open({
          parent: this,
          component: MiOrder,
          props: {
            statusShow: true
          },
          hasModalCard: true,
          fullScreen: true,
          canCancel: false,
          scroll: 'keep'
        });
      } else {
        this.showCartRigth = true;
      }
    },
    showProduct (info) {
      if (info.vendor.modeMenu === 1) {
        this.$buefy.modal.open({
          parent: this,
          component: popModeMenu,
          props: {
            product: info.product,
            vendor: info.vendor,
            index: info.index,
            vindex: info.vindex,
            catName: info.catName
          },
          hasModalCard: true,
          fullScreen: true,
          canCancel: false,
          scroll: 'keep'
        });
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: ProductView,
          props: {
            product: info.product,
            vendor: info.vendor,
            index: info.index,
            vindex: info.vindex,
            catName: info.catName
          },
          customClass: 'popProducto',
          hasModalCard: true,
          canCancel: false,
          scroll: 'keep'
        });
      }
    },
    encryptInfo (info) {
      return lcrypt.encode(info);
    },
    decryptInfo (info) {
      return lcrypt.decode(info);
    },
    urlVendor () {
      let domain = window.location.hostname.split('.')[1];
      let subdomain = window.location.hostname.split('.')[0];
      if (subdomain === 'www') {
        subdomain = window.location.hostname.split('.')[1];
        domain = window.location.hostname.split('.')[2];
      }
      if (subdomain === 'domicilios' && domain !== 'precompro') {
        subdomain = window.location.hostname;
      }
      return (
        subdomain === 'marketplace' ||
          subdomain === 'dfoodmarket' ||
          subdomain === 'localhost' ||
          subdomain === '192' ||
          subdomain === 'domicilios'
      ) ? 'all' : subdomain;
    }
  },
  watch: {
    getProductInfo () {
      if (Object.keys(this.getProductInfo).length > 0) {
        this.showProduct(this.getProductInfo);
      }
    }
  }
};
</script>

<style lang="scss">
.btn-action-home {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--buttonTextColor);
  background-color: var(--buttonBackground);
  height: 100%;
  padding: 10px;
  margin-left: 10px;
  border-radius: 15px;
  width: 105px;
  max-height: 27px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  svg {
    margin-right: 5px;
  }
}
.btn-action-home:hover {
  background-color: var(--buttonBackground);
  color: var(--buttonTextColor);
}
.notPadding {
  padding: 0px!important;
}
.modeMenuNavBar {
  flex-direction: column;
}
  .modeMenuFooter{
          justify-content: center !important;
          min-height: 80px !important;
  }
#app {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.items-cart {
  float: right;
  position: absolute;
  top: -8px;
  right: -10px;
  background-color: var(--buttonBackground);
  color: var(--buttonTextColor);
  font-weight: 700;
  border-radius: 99px;
  width: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
}
.btn-home {
  margin-left: 10px;
  margin-right: 10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: var(--buttonBackground);
  padding: 10px;
  cursor: pointer;
  &.order{
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius:100px;
    margin:0 -7px;
  }
  .iconColor {
    color: var(--buttonTextColor);
  }
}
.flex{
  display: flex;
  order: 2;
  &.modeCarta{
    width: 100%;
  }
}
.m-left-5 {
  margin-left: 5px;
}
.modal-card-foot {
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
@media(max-width: 635px) {
  .btn-action-home {
    position: absolute!important;
    top: 15px!important;
    height: 55%!important;
    width: auto!important;
    margin-left: inherit;
    padding: 8px;
  }
}
@import './assets/styles/index.scss';
</style>
