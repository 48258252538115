<template>
    <div>
    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false">
      <b-icon icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>
      <div class="cart-header">
        <b-button style="border: inherit;float: right;" class="cart-header-btn-close" @click="$parent.close()"><i class="icon-mks icon_close black" style="height: 15px;width: 15px;"></i></b-button>
      </div>
      <div class="cart-content">
          <span class="text bold segoeBold size-23 border-bot center">Finaliza tu pedido</span>
          <div class="select-zone">
            <span class="text bold segoeBold size-16">Selecciona la zona</span>
            <div class="cont-zone">
              <div class="zone" :class="{active: zone.zone == orderData.zone}" v-for="(zone, zonel) in zoneList" :key="'zones#' + zonel" @click="getTable(zone)">
                <span class="text bold size-20 absolute-center">{{ zone.zone }}</span>
              </div>
            </div>
          </div>
          <div class="select-mesa">
            <span class="text bold segoeBold size-16">Selecciona la mesa en la que te encuentras</span>
            <div class="cont-mesa">
              <div class="mesa" :class="{active: table.table == orderData.table}" v-for="(table, tablel) in tableList" :key="'tables#' + tablel" @click="setTable(table)">
                <span class="text bold size-11 absolute-center nowrap">{{ table.table }}</span>
              </div>
            </div>
          </div>
          <div class="comment-cont">
              <span class="text bold family-bold size-16 margin-bot-10 margin-top-10">¿Algún comentario adicional?</span>
              <textarea cols="30" id="comments" rows="3" v-model="orderData.comments" placeholder="Agregar comentario"></textarea>
           <b-field horizontal label="Nombre (Opcional)" :type="{'is-danger': errors.has('name')}" :message="errors.first('name')">
              <b-input type="text"
                  v-model="orderData.name"
                  placeholder="Nombre del titular"
                  v-validate="'min:2'"
                  id="name"
                  name="name"
                  icon-pack="fa" icon=""></b-input>
            </b-field>
            <b-field horizontal label="Teléfono (Opcional)" :type="{'is-danger': errors.has('phone')}" :message="errors.first('phone')">
              <b-input  type="text"
                name="phone"
                id="phone"
                placeholder="Celular"
                v-model="orderData.phone"
                v-validate="'min:7|max:10'" expanded autofocus icon-pack="fa"></b-input>
            </b-field>
            <b-field horizontal label="Email (Opcional)" :type="{'is-danger': errors.has('email')}" :message="errors.first('email')">
              <b-input
                type="email"
                name="email"
                id="email"
                v-model="orderData.email"
                placeholder="Email"
                v-validate="'email'" icon-pack="fa" icon=""></b-input>
            </b-field>
          </div>
          <div id="paymentEndOrder" class="payment-cont">
              <span class="text bold segoe size-19 margin-bot-10 margin-top-10">Selecciona un método de pago</span>
              <div class="tabs-cont">
                <b-tabs v-model="activeTab">
                    <b-tab-item label="Pago en la mesa">
                        <div class="pay-cont">
                            <span class="text bold segoe size-16" style="display:flex"><i style="margin-right:5px" class="icon-mks icon_cash_menu black"></i>  Pago en efectivo</span>
                            <b-radio v-model="radioTable" native-value="0"></b-radio>
                        </div>
                        <div class="pay-cont">
                            <span class="text bold segoe size-16" style="display: flex;"> <i class="icon-mks black icon_dataPhone" style="margin-right:5px"></i> Datáfono</span>
                            <b-radio v-model="radioTable" native-value="1"></b-radio>
                        </div>
                        <span class="text segoe size-12">Al final un mesero pasara a tu mesa con tu cuenta</span>
                    </b-tab-item>
                    <b-tab-item label="Pago en línea">
                        <div class="pay-cont">
                            <span class="text bold segoe size-16"><i class="fas fa-credit-card"></i>  Tarjeta de crédito</span>
                            <b-radio v-model="radioCard"
                            native-value="0">
                            </b-radio>
                        </div>
                        <div class="pay-cont" v-if="showPse">
                            <span class="text bold segoe size-16"> <i class="far fa-credit-card"></i>  Tarjeta débito</span>
                            <b-radio v-model="radioCard"
                                native-value="1">
                            </b-radio>
                        </div>
                        <span class="text segoe size-12">Al final un mesero pasara a tu mesa con tu cuenta</span>
                    </b-tab-item>
                </b-tabs>
              </div>
          </div>
          <div class="btn-end">
            <b-button class="btn-finalizar-pedido" @click="methodPayOrder()"><span v-if="activeTab == 1" class="text bold sie-18">Continuar</span><span v-if="activeTab == 0" class="text bold sie-18">Realizar pago</span></b-button>
          </div>
      </div>
    <TransactionPending v-model="showPending" v-if="showPending" @clear="clearTime"></TransactionPending>
    <TransactionDeclined v-model="showDeclined" v-if="showDeclined"></TransactionDeclined>
   </div>
</template>
<script>
import paymentCardPU from '@/components/modeCarta/payU/paymentCard';
import paymentPsePU from '@/components/modeCarta/payU/paymentPse';
import paymentCardMP from '@/components/modeCarta/mercadoPago/paymentCard';
import paymentPseMP from '@/components/modeCarta/mercadoPago/paymentPse';
import OrderStatus from '@/components/modeCarta/OrderStatus';
import { mapGetters } from 'vuex';
import TransactionPending from '@/components/transactionStatus/TransactionPending';
import TransactionDeclined from '@/components/transactionStatus/TransactionDeclined';
import LocalStorage from 'store';
import moment from 'moment';
import credentials from '@/helpers/pay.json';
import _ from 'lodash';
export default {
  components: { TransactionDeclined, TransactionPending },
  data () {
    return {
      zoneList: [],
      tableList: [],
      activeTab: 1,
      radioCard: null,
      radioTable: null,
      orderData: {
        vendor: {
          id: null,
          name: null,
          vendorId: null,
          logo: null
        },
        zone: null,
        table: null,
        sessionId: null,
        name: null,
        phone: null,
        email: null,
        address: null,
        origin: 'menu',
        products: [],
        stateId: 0,
        totalPrice: 0,
        delivery: 0,
        tip: 0,
        deliveryType: null,
        comments: null,
        timeDelivery: null,
        providerTransaction: null
      },
      freeProducts: [],
      payProducts: [],
      normalProducts: [],
      arrayProducts: [],
      orderValid: null,
      delivery: 0,
      methodPay: null,
      pinPay: null,
      pinAccess: null,
      showPay: false,
      load: false,
      showPending: false,
      showDeclined: false,
      showApproved: false,
      date: null,
      minDatetime: moment().toISOString(),
      availability: [],
      orderId: null,
      config: {
        toolbarColor: '#151E26',
        footerColor: '#151E26',
        footerTextColor: '#ffffff',
        buttonColor: '#559A70',
        buttonTextColor: '#ffffff'
      },
      modalTip: false,
      showNotAvailability: false,
      intervalPending: null,
      blocked: false,
      showPse: true,
      loading: false
    };
  },
  computed: {
    ...mapGetters([
      'getCart',
      'getVendor',
      'paymentType',
      'typePayment'
    ])
  },
  mounted () {
    this.$store.commit('setTypePayment', false);
    this.getInfoVendor(this.$route.params.id);
    const trans = this.$route.query;
    if (Object.keys(trans).length > 0) {
      if (trans.source_utm) {
        const transactionId = LocalStorage.get('processId');
        if (transactionId !== undefined) {
          this.blocked = true;
          this.validatePaymentId(transactionId);
        } else {
          this.blocked = false;
          const actual = window.location.href;
          const common = actual.replace('?source_utm=mp', '');
          window.location.href = common;
        }
      } else {
        this.showStatusTransaction(trans);
      }
    }
    this.getStatusPSE();
  },
  methods: {
    methodPayOrder () {
      if (this.orderData.zone !== null && this.orderData.table !== null) {
        this.orderData.deliveryType = 'carta';
        const data = this.orderData;
        if (this.radioTable === 1 || this.radioCard === 1 || this.radioTable === 0 || this.radioCard === 0) {
          if (data.timeDelivery == null) {
            delete data.timeDelivery;
          }
          if (data.name == null) {
            data.name = 'Orden en mesa';
          }
          if (data.address == null) {
            data.address = data.zone + ' - ' + data.table;
          }
          if (data.phone == null) {
            data.phone = '0000000';
          }
          if (data.email == null) {
            delete data.email;
          }
        }
        const thes = this;
        data.products = this.arrayProducts;
        if (this.activeTab === 1 && this.radioCard === 0 && this.typePayment === 'payU') {
          data.providerTransaction = 'payU';
          this.$buefy.modal.open({
            parent: this,
            component: paymentCardPU,
            props: {
              order: data
            },
            events: {
              pendingTransactionCard (event) {
                thes.showPending = event;
              },
              approvedTransactionCard (event) {
                thes.$buefy.modal.open({
                  parent: thes,
                  component: OrderStatus,
                  props: {
                    order: event,
                    orderId: thes.orderId,
                    showApproved: true
                  },
                  hasModalCard: true,
                  fullScreen: true,
                  canCancel: false,
                  scroll: 'keep'
                });
                thes.$parent.close();
              // console.log($event) // toda la orden completa miguel
              }
            },
            hasModalCard: true,
            fullScreen: true,
            canCancel: false,
            scroll: 'keep'
          });
        } else if (this.activeTab === 1 && this.radioCard === 1 && this.typePayment === 'payU') {
          data.providerTransaction = 'payU';
          this.$buefy.modal.open({
            parent: this,
            component: paymentPsePU,
            props: {
              order: data
            },
            hasModalCard: true,
            fullScreen: true,
            canCancel: false,
            scroll: 'keep'
          });
        } else if (this.activeTab === 1 && this.radioCard === 0 && this.typePayment === 'mercadoPago') {
          data.providerTransaction = 'mercadoPago';
          this.$buefy.modal.open({
            parent: this,
            component: paymentCardMP,
            props: {
              order: data
            },
            events: {
              pendingTransactionCard (event) {
                thes.showPending = event;
              },
              approvedTransactionCard (event) {
                thes.$buefy.modal.open({
                  parent: thes,
                  component: OrderStatus,
                  props: {
                    order: event,
                    orderId: thes.orderId,
                    showApproved: true
                  },
                  hasModalCard: true,
                  fullScreen: true,
                  canCancel: false,
                  scroll: 'keep'
                });
                thes.$parent.close();
              }
            },
            hasModalCard: true,
            fullScreen: true,
            canCancel: false,
            scroll: 'keep'
          });
        } else if (this.activeTab === 1 && this.radioCard === 1 && this.typePayment === 'mercadoPago') {
          data.providerTransaction = 'mercadoPago';
          this.$buefy.modal.open({
            parent: this,
            component: paymentPseMP,
            props: {
              order: data
            },
            hasModalCard: true,
            fullScreen: true,
            canCancel: false,
            scroll: 'keep'
          });
        } else {
          if (this.radioTable === 0) {
            data.providerTransaction = 'efectivo';
            this.sendOrder(data);
          } else if (this.radioTable === 1) {
            data.providerTransaction = 'datáfono';
            this.sendOrder(data);
          } else {
            this.$buefy.toast.open({
              duration: 3000,
              message: 'Debe elegir método de pago.',
              position: 'is-top',
              type: 'is-danger'
            });
          }
        }
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Debe seleccionar zona y mesa',
          position: 'is-top',
          type: 'is-danger'
        });
      }
    },
    getZones (id) {
      this.loading = true;
      this.$http.get('/marketplace/client/vendor/zones/' + id).then(({ data }) => {
        this.zoneList = data.data;
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    getTable (data) {
      this.orderData.table = null;
      if (this.orderData.zone !== data.zone) {
        this.orderData.zone = data.zone;
        this.loading = true;
        this.$http.get('/marketplace/client/vendor/tables/zone/' + data._id).then(({ data }) => {
          this.tableList = data.data;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$parent.listErrors(err.request.response, err.request.status),
            position: 'is-top',
            type: 'is-danger'
          });
        });
      } else {
        this.orderData.zone = null;
        this.tableList = null;
      }
    },
    setTable (value) {
      this.orderData.table = value.table;
    },
    getInfoVendor (id) {
      this.loading = true;
      this.$http.get('/marketplace/client/vendor/' + id).then(({ data }) => {
        if (data.data.urlModeMenu !== undefined) {
          if (data.data.urlModeMenu === this.$parent.urlVendor()) {
            data.data.showMenu = 1;
          } else {
            data.data.showMenu = 0;
          }
        } else if (data.data.modeMenu) {
          data.data.showMenu = 1;
        } else {
          data.data.showMenu = 0;
        }
        this.$store.commit('setVendor', data.data);
        this.loading = false;
        if (!this.blocked) {
          this.dataTransform();
        } else {
          this.load = true;
        }
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      }).finally(() => {
        if (this.getVendor.onlinePay !== undefined) {
          const mp = _.find(this.getVendor.onlinePay, { provider: 'mercadoPago' });
          if (mp !== undefined) {
            let accountId = 'precompro';
            if (this.getVendor.accountName !== undefined) {
              if (this.getVendor.accountName !== 0) {
                accountId = this.getVendor.accountName;
              }
            }
            window.Mercadopago.setPublishableKey(credentials[process.env.NODE_ENV][accountId]);
          }
        }
        this.getZones(this.getVendor._id);
      });
    },
    dataTransform () {
      const copyTest = JSON.parse(JSON.stringify(this.getCart.products));
      this.implementDeals(copyTest);
      this.pushNormal(this.normalProducts);
      this.pushDeals(this.payProducts, true);
      this.pushDeals(this.freeProducts, false);
      this.totalPrice(this.arrayProducts);
      if (this.getVendor.payDeliveryBefore === undefined || this.getVendor.payDeliveryBefore === 0) {
        if (this.getVendor.deliveryPrice !== undefined) {
          if (this.getVendor.deliveryPrice.type === 'fix') {
            this.delivery = this.getVendor.deliveryPrice.price;
          } else if (this.getVendor.deliveryPrice.type === 'percent') {
            this.delivery = (this.orderData.totalPrice * this.getVendor.deliveryPrice.price) / 100;
          }
        }
      } else if (this.getVendor.payDeliveryBefore <= this.orderData.totalPrice) {
        this.delivery = 0;
      } else if (this.getVendor.payDeliveryBefore >= this.orderData.totalPrice) {
        if (this.getVendor.deliveryPrice.type === 'fix') {
          this.delivery = this.getVendor.deliveryPrice.price;
        } else if (this.getVendor.deliveryPrice.type === 'percent') {
          this.delivery = (this.orderData.totalPrice * this.getVendor.deliveryPrice.price) / 100;
        }
      }
      this.orderData.sessionId = this.getCart.sessionId;
      this.orderData.vendor.id = this.getVendor._id;
      this.orderData.vendor.vendorId = this.getVendor.accountName || 0;
      this.orderData.vendor.name = this.getVendor.displayName;
      this.orderData.vendor.logo = this.getVendor.logo;
      this.orderData.products = this.arrayProducts;
      this.orderData.delivery = this.delivery;
      this.load = true;
    },
    implementDeals (productsData) {
      let dosXuno = [];
      let tresXdos = [];
      const thes = this;
      _.forEach(productsData, function (pd) {
        if (Object.keys(pd.deal).length > 0) {
          if (pd.deal[0].type === '2x1') {
            dosXuno.push(pd);
          }
          if (pd.deal[0].type === '3x2') {
            tresXdos.push(pd);
          }
        } else {
          thes.normalProducts.push(pd);
        }
      });
      dosXuno = _.orderBy(dosXuno, ['price'], ['desc']);
      tresXdos = _.orderBy(tresXdos, ['price'], ['desc']);
      if (Object.keys(dosXuno).length > 0) {
        this.get2x1(dosXuno);
      }
      if (Object.keys(tresXdos).length > 0) {
        this.get3x2(tresXdos);
      }
    },
    get2x1 (dosXuno) {
      let quantityAll = 0;
      let flagQ = 0;
      const thes = this;
      _.forEach(dosXuno, function (item2x1) {
        quantityAll += item2x1.quantity;
      });
      let halfValue = Math.ceil(quantityAll / 2);
      _.forEach(dosXuno, function (item2x1) {
        if (halfValue > 0) {
          if (item2x1.quantity <= halfValue) {
            const itemToPush = JSON.parse(JSON.stringify(item2x1));
            thes.payProducts.push(itemToPush);
            halfValue = halfValue - itemToPush.quantity;
          } else {
            flagQ = item2x1.quantity - halfValue;
            const itemToPush2 = JSON.parse(JSON.stringify(item2x1));
            itemToPush2.quantity = halfValue;
            thes.payProducts.push(itemToPush2);
            const itemToPush3 = JSON.parse(JSON.stringify(item2x1));
            itemToPush3.quantity = flagQ;
            thes.freeProducts.push(itemToPush3);
            halfValue = 0;
          }
        } else {
          const itemToPush = JSON.parse(JSON.stringify(item2x1));
          thes.freeProducts.push(itemToPush);
        }
      });
    },
    get3x2 (tresXdos) {
      let quantityAll = 0;
      let flagQ = 0;
      const thes = this;
      _.forEach(tresXdos, function (item3x2) {
        quantityAll += item3x2.quantity;
      });
      let thirdValue = 0;
      if (quantityAll === 3 || quantityAll === 2) {
        thirdValue = 2;
      } else {
        thirdValue = Math.ceil(quantityAll / 3 * 2);
      }
      _.forEach(tresXdos, function (item3x2) {
        if (thirdValue > 0) {
          if (item3x2.quantity <= thirdValue) {
            const itemToPush = JSON.parse(JSON.stringify(item3x2));
            thes.payProducts.push(itemToPush);
            thirdValue = thirdValue - itemToPush.quantity;
          } else {
            flagQ = item3x2.quantity - thirdValue;
            const itemToPush2 = JSON.parse(JSON.stringify(item3x2));
            itemToPush2.quantity = thirdValue;
            thes.payProducts.push(itemToPush2);
            const itemToPush3 = JSON.parse(JSON.stringify(item3x2));
            itemToPush3.quantity = flagQ;
            thes.freeProducts.push(itemToPush3);
            thirdValue = 0;
          }
        } else {
          const itemToPush = JSON.parse(JSON.stringify(item3x2));
          thes.freeProducts.push(itemToPush);
        }
      });
    },
    pushNormal (products) {
      Object.values(products).map((item) => {
        const arr = {};
        let totalPrice = 0;
        arr._id = item._id;
        arr.name = item.name;
        arr.price = item.price;
        arr.quantity = item.quantity;
        if (item.options !== undefined) {
          arr.options = item.options;
        }
        if (item.percentOff == null || item.percentOff === undefined) {
          totalPrice = item.price * item.quantity;
        } else {
          const discount = item.price - (item.price * item.percentOff / 100);
          arr.discountPrice = discount;
          totalPrice = discount * item.quantity;
        }
        arr.totalPrice = totalPrice;
        arr.image = item.image;
        this.arrayProducts.push(arr);
      });
    },
    pushDeals (products, pay) {
      Object.values(products).map((item) => {
        let price = item.price;
        if (pay === false) {
          price = 0;
        }
        const arr = {};
        let totalPrice = 0;
        arr._id = item._id;
        arr.name = item.name;
        arr.price = price;
        arr.quantity = item.quantity;
        arr.deal = item.deal[0].name;
        totalPrice = price * item.quantity;
        arr.totalPrice = totalPrice;
        arr.image = item.image;
        if (item.options !== undefined) {
          arr.options = item.options;
        }
        this.arrayProducts.push(arr);
      });
    },
    totalPrice (products) {
      let total = 0;
      Object.values(products).map((item) => {
        total += item.totalPrice;
      });
      this.totalPrice = total;
      this.orderData.totalPrice = total;
    },
    validatePaymentId (id) {
      this.loading = true;
      this.$http.post('/marketplace/payment/status', { processId: id }).then(({ data }) => {
        if (data.code === 200) {
          if (data.state === 'rejected') {
            LocalStorage.remove('processId');
            this.showDeclined = true;
          }
          if (data.state === 'approved') {
            this.obtainOrder(id);
          }
          if (data.state === 'in_progress' || data.state === 'pending') {
            this.showPending = true;
            const instance = this;
            this.intervalPending = setInterval(() => {
              instance.validatePaymentId(id);
            }, 10000);
          }
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    obtainOrder (id) {
      this.$http.get('/marketplace/payment/order/' + id).then(({ data }) => {
        if (data.code === 200) {
          this.orderId = data.orderId;
          this.showApproved = true;
          LocalStorage.remove('processId');
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Orden no existe!',
            position: 'is-top',
            type: 'is-danger'
          });
        }
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    showStatusTransaction (transaction) {
      if (parseInt(transaction.polTransactionState) === 4 && parseInt(transaction.polResponseCode) === 1) {
        this.showApproved = true;
      }
      if (parseInt(transaction.polTransactionState) === 6 && (parseInt(transaction.polResponseCode) === 4 || parseInt(transaction.polResponseCode) === 5)) {
        this.showDeclined = true;
      }
      if ((parseInt(transaction.polTransactionState) === 12 || parseInt(transaction.polTransactionState) === 14) && (parseInt(transaction.polResponseCode) === 9994 || parseInt(transaction.polResponseCode) === 25)) {
        this.showPending = true;
      }
    },
    getStatusPSE () {
      this.$http.get('marketplace/payment/banks/pse').then(({ data }) => {
        if (data.code === 200) {
          const pse = _.find(data.data, { id: 'pse' });
          if (pse) {
            this.showPse = true;
          } else {
            this.showPse = false;
          }
        } else {
          this.showPse = false;
        }
      });
    },
    sendOrder (data) {
      this.loading = true;
      this.$http.post('marketplace/client/order/menu', data).then(({ data }) => {
        if (data.cart) {
          this.$store.commit('setCart', []);
        }
        this.$buefy.toast.open({
          duration: 3000,
          message: data.message,
          position: 'is-top',
          type: 'is-success'
        });
        this.$buefy.modal.open({
          parent: this,
          component: OrderStatus,
          props: {
            order: data.data.orderInfo,
            showApproved: false
          },
          hasModalCard: true,
          fullScreen: true,
          canCancel: false,
          scroll: 'keep'
        });
        this.loading = false;
        this.$parent.close();
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    }
  },
  watch: {
    activeTab () {
      if (this.activeTab === 0) {
        this.radioCard = null;
      } else if (this.activeTab === 1) {
        this.radioTable = null;
      }
    },
    radioCard () {
      let payU = null;
      let mp = null;
      Object.values(this.getVendor.onlinePay).map(item => {
        if (item.provider === 'mercadoPago') {
          mp = item;
        }
      });
      Object.values(this.getVendor.onlinePay).map(itemDos => {
        if (itemDos.provider === 'payU') {
          payU = itemDos;
        }
      });
      if (mp) {
        this.$store.commit('setTypePayment', 'mercadoPago');
      } else {
        if (payU) {
          this.$store.commit('setTypePayment', 'payU');
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
    .cart-header{
        padding: 5px;
        height: 40px;
    }
    .cart-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 25px;
    }
    .text{
        &.border-bot{
            border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
            padding-bottom: 5px;
            width: 100%;
        }
        &.absolute-center{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .select-zone{
        width: 100%;
        border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
        padding: 10px 0;
        .cont-zone{
                display: -webkit-box;
                width: 100%;
                overflow-x: auto;
                padding: 10px;
            .zone{
                height: 73px;
                width: 95px;
                background: #F3F3F3;
                border-radius: 7px;
                box-shadow: 0px 1px 6px #0000004D;
                position: relative;
                margin-right: 10px;
                &.active{
                background: #CDD500;
                }
            }
        }
    }
    .select-mesa{
        width: 100%;
        border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
        padding: 10px 0;
        .cont-mesa{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;
                overflow-x: auto;
                padding: 10px;
                max-height: 145px;
            .mesa{
                height: 26px;
                width: 68px;
                background: #D2D2D2 0% 0% no-repeat padding-box;
                border-radius: 4px;
                position: relative;
                margin-right: 10px;
                margin-top: 5px;
                &.active{
                background: #CDD500;
                }
            }
        }
    }
    .comment-cont{
        display: flex;
        flex-direction: column;
        width: 100%;
        #comments{
            border: 2px solid #cfcdcd6e;
            padding: 15px;
            margin-bottom: 5px;
        }
    }
    .payment-cont{
        margin-top: 20px ;
        width: 100%;
        border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
    }
    .pay-cont{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    .btn-end{
    margin: 20px 0;
                width: 100%;
            height: 45px;
            max-width: 350px;
        .btn-finalizar-pedido{
            background: var(--buttonBackground);
            color: var(--buttonTextColor) !important;
            .text{
                  color: var(--buttonTextColor) !important;
            }
            width: 100%;
            height: 45px;
            max-width: 350px;
        }
    }

</style>
