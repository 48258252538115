import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Buefy from 'buefy';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMoment from 'vue-moment';
import Vue2Filters from 'vue2-filters';
import moment from 'moment-timezone';
import vClickOutside from 'v-click-outside';
import langEs from '@/lang/es';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Datetime } from 'vue-datetime';
import VeeValidate, { Validator } from 'vee-validate';
import VueSocketIO from 'vue-socket.io-extended';
import LocalStorage from 'store';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import VueMobileDetection from 'vue-mobile-detection';
import VueMeta from 'vue-meta';
import * as VueGoogleMaps from 'vue2-google-maps';

import 'buefy/dist/buefy.css';
import 'vue-datetime/dist/vue-datetime.css';

// scroll drag
import VueDragscroll from 'vue-dragscroll';
Vue.use(VueDragscroll);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.use(Buefy, {
  defaultIconPack: 'fas'
});
const Axios = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: false
});
Axios.interceptors.request.use((request) => {
  if (request.data && !request.headers['Content-Type']) {
    request.headers.Accept = 'application/json';
    request.headers['Content-Type'] = 'application/json';
    request.data = JSON.stringify(request.data);
  }
  return request;
});
Axios.interceptors.response.use(undefined, err => {
  const res = err.response;
  if (res.status === 401 && res.config && !res.config.__isRetryRequest) {
    return Promise.reject(err);
  }
  if (res.status === 403 || res.status === 500) {
    return Promise.reject(err);
  }
});

moment.tz.setDefault('America/Bogota');
Vue.use(VueMoment, { moment });
Vue.use(VueAxios, Axios);
Vue.use(vClickOutside);
Vue.use(Vue2Filters);
Vue.config.productionTip = false;
Vue.component('f-icon', FontAwesomeIcon);
Vue.use(VeeValidate);
Validator.localize('es', langEs);
Vue.component('datetime', Datetime);
Vue.use(VueMobileDetection);
if (LocalStorage.get('domicompro-uid-user') === undefined) {
  LocalStorage.set('domicompro-uid-user', uuidv4());
}
Vue.use(VueSocketIO, io(process.env.VUE_APP_WS, {
  autoConnect: true,
  reconnection: true,
  forceNew: true,
  reconnectionAttempts: Infinity,
  transports: ['websocket'],
  upgrade: false,
  query: {
    user: LocalStorage.get('domicompro-uid-user')
  }
}));
library.add(fab, fas, far);
dom.watch();

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBEiuJ8FGGFTuBE3qKCw8oFiycGuVVLvSc',
    libraries: 'places,drawing' // necessary for places input
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
