import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    carts: [],
    vendor: [],
    paymentType: false,
    typePayment: null,
    productInfo: [],
    globalLoader: false,
    loader: false,
    tipStorage: 0,
    addressCoverage: null,
    cardTypeInfo: null
  },
  getters: {
    getCart: state => state.carts,
    getProductInfo: state => state.productInfo,
    getVendor: state => state.vendor,
    paymentType: state => state.paymentType,
    loader: state => state.loader,
    globalLoader: state => state.globalLoader,
    typePayment: state => state.typePayment,
    tipStorage: state => state.tipStorage,
    addressCoverage: state => state.addressCoverage,
    getCardTypeInfo: state => state.cardTypeInfo
  },
  mutations: {
    setCardTypeInfo (state, data) {
      state.cardTypeInfo = data;
    },
    setTypePayment (state, data) {
      state.typePayment = data;
    },
    setPaymentType (state, data) {
      state.paymentType = data;
    },
    setProductInfo (state, data) {
      state.productInfo = data;
    },
    setCart (state, data) {
      state.carts = data;
    },
    setVendor (state, data) {
      state.vendor = data;
    },
    setGlobalLoader (state, data) {
      state.globalLoader = data;
    },
    setLoader (state, data) {
      state.loader = data;
    },
    setTipStorage (state, data) {
      state.tipStorage = data;
    },
    setAddressCoverage (state, data) {
      state.addressCoverage = data;
    }
  }
});
