<template>
  <b-modal :active.sync="value" :can-cancel="false" >
      <div class="modals-bosy">
         <div class="center_icon_top">
            <IconCheck fill="rgb(40, 167, 69)" width="120px"></IconCheck>
         </div>
          <div>
            <h3 class="title-modal">Transacción Aprobada</h3>
            <div class="text-lef-messages">
              <h5 class="title-confirm">
                Tu pedido ha sido recibido con éxito
              </h5>
            </div>
            <div class="btns-modal">
              <button  type="button"
                data-dismiss="modal"
                style="padding: 5px 40px;"
                @click="goAccount()"
                class="full-btn-modal btn-modal-fill full-w">Ver Estado Pedido</button>
            </div>
          </div>
      </div>
  </b-modal>
</template>
<script>
import IconCheck from '@/components/icons/iconCheck';
import { mapGetters } from 'vuex';
export default {
  props: ['idOrder'],
  components: { IconCheck },
  data () {
    return {
      value: true
    };
  },
  computed: {
    ...mapGetters([
      'getVendor'
    ])
  },
  methods: {
    goAccount () {
      if (this.getVendor.modeMenu === 0) {
        this.$emit('input', false);
        if (this.idOrder != null) {
          this.$router.replace('/' + this.idOrder + '/orderQuery');
        } else {
          this.$router.replace('/');
        }
      } else {
        this.value = false;
      }
    }
  }
};
</script>
<style lang="scss" >
.ConfirmBox .animation-content.modal-content {
  max-width: 550px !important;
}

.center_icon_top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 30px 0;
}
.text-lef-messages {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding-top: 0px;
  text-align: center;
  p {
    padding-bottom: 10px;
  }
}

.title-confirm {
  padding: 20px 0 15px;
  color: #000;
  font-weight: 500;
}

.final-message {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 10px;
  margin-bottom: -15px;
}

.item-l {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #000;
  &:last-child {
    border-right: none;
  }
}
.separator-v {
  height: 50px !important;
  background-color: #000 !important;
  opacity: unset !important;
}

.id_full{
  width:100% !important;
}
  .modals-bosy {
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-modal{
      text-align: center;
    }
    .btns-modal{
      margin-top: 15px !important;
      display: flex;
      flex-wrap: wrap;
      button{
        width: 364px !important;
        padding: 10px !important;
        margin: auto !important;
        text-align:center;
        margin-top:10px !important;
      }
    }
  }
</style>
