<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="108.27" height="108.27" viewBox="0 0 108.27 108.27"  :style="'enable-background:new 0 0 1366 768; width:'+width" xml:space="preserve" x="0px" y="0px">
  <g id="Icon_feather-clock" data-name="Icon feather-clock" transform="translate(0.5 0.5)">
    <path   id="Trazado_8936" data-name="Trazado 8936" d="M104.27,53.635A50.635,50.635,0,1,1,53.635,3,50.635,50.635,0,0,1,104.27,53.635Z" transform="translate(0 0)" fill="none" stroke="#d99332" stroke-linecap="round" stroke-linejoin="round" stroke-width="7"/>
    <path  id="Trazado_8937" data-name="Trazado 8937" d="M18,9V39.381L38.254,49.508" transform="translate(35.635 14.254)" fill="none" stroke="#d99332" stroke-linecap="round" stroke-linejoin="round" stroke-width="7"/>
  </g>
</svg>

</template>
<script>
export default {
  props: ['fill', 'width']
};
</script>
