<template>
    <div class="aside-container ps ps--active-y" :class="{advance: Object.keys(cart).length == 0 || Object.keys(cart.products).length == 0, 'center-div': Object.keys(cart).length == 0 || Object.keys(cart.products).length == 0}">
      <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false">
        <b-icon icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
      </b-loading>
    <b-message id="messDelete" :active.sync="messDelete">
      <div class="cont-info">
      <span class="text bold size-20" style="display: flex;align-items: center;"><i class="icon-mks icon_alert red" style="margin-right:10px"></i> Producto eliminado</span>
      <span class="text size-15">Se eliminó {{messDeletePro.name}} al pedido</span>
       </div>
    </b-message>
      <div class="cart-header">
        <b-button class="cart-header-btn-close" @click="$parent.close()"><i class="icon-mks icon_close black" style="height: 15px;width: 15px;"></i></b-button>
      </div>
      <div class="cart-content" :class="{'bar-progre': getTotal(cart.products) < getVendor.minPriceForOrder}" v-if="Object.keys(cart).length > 0 && Object.keys(cart.products).length > 0">
        <span class="text segoeBold bold size-23 margin-bot-20">Mi pedido</span>
        <article class="media" v-for="(product, pIndex) in cart.products" :key="'cart-product#' + pIndex">
          <div class="media-content">
            <div class="content">
              <div class="cont-title-product">
                <span class="text bold segoeBold size-18" style="text-transform: uppercase;">
                  {{ shortText(product.name) }}
                    <img src="@/assets/icons/2x1.svg" v-show="isDeals(product.deal, '2x1')" :style="{right: (product.specs) ? '30px' : '10px'}">
                    <img src="@/assets/icons/3x2.svg" v-show="isDeals(product.deal, '3x2')" :style="{right: (product.specs) ? '30px' : '10px'}">
                </span>
                <div class="itemCart">
                  <span class="cartPrice">
                    <span class="text bold size-16" :class="{'textLine': product.percentOff}">{{ product.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
                    <span class="cartPrice-discountPercent" v-if="product.percentOff">-{{ product.percentOff }}%</span>
                  </span>
                  <span class="text bold size-16" v-if="product.percentOff">{{ getTotalProduct(product) | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
                </div>
              </div>
              <div class="is-white-flex flex-col" v-if="product.options">
                <div v-for="(adi, iadi) in product.options.products" :key="'adition' + iadi">
                  <span class="text bold size-16">{{ shortText(adi.name) }}</span>
                  <div class="addons-cont" v-for="(pItem, io) in adi.items" :key="'itemProd' + io">
                    <span  class="text segoe size-12" >{{ pItem.item }} x 1</span>
                    <span class="text segoe size-12">{{ pItem.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
                  </div>
                </div>
              </div>
              <div class="is-white-flex" v-if="product.description">
                <span class="text segoe size-12 ellip-desc">{{product.description}}</span>
              </div>
              <div class="is-white-flex m-top-5">
                <div class="btn-minus-plus">
                  <span id="minus" v-show="product.quantity == 1" @click="deleteProductCart(product._id, pIndex,product)"><i class="fa fa-minus"></i></span>
                  <span id="minus" v-show="product.quantity >= 2" @click="updateCart(product, 'min', pIndex)"><i class="fa fa-minus"></i></span>
                  <span id="quantity" class="text semi-bold size-23">{{ product.quantity }}</span>
                  <span id="plus" @click="updateCart(product, 'max', pIndex)"><i class="fa fa-plus"></i></span>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div class="p-10 hight-100" v-else>
        <div class="center-div" style="postion:relative">
          <img src="@/assets/image/cartaSelect.svg" style="margin-bottom:10px" alt="">
          <div class="text bold size-18 margin-bot-10"><strong id="cartEmpty">Aún no tienes productos agregados</strong></div>
          <div class="text size-12" style="padding: 0 50px;">Selecciona la comida que mas te gusta en los productos de nuestro menú</div>
          <a class="btn-menu" @click="$parent.close()"><span class="text bold 18px">Ir al menú</span></a>
        </div>
      </div>
      <div class="p-10 infoCart" v-if="Object.keys(cart).length > 0 && Object.keys(cart.products).length > 0">
        <div class="infoCart-item">
          <span class="text bold size-16">Subtotal</span>
          <span class="text segoe size-16">{{ getSubTotal(cart.products)  | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
        </div>
        <div class="infoCart-item">
          <span class="text bold size-16">Descuento</span>
          <span class="text segoe size-16">- {{ getTotalDescuento(cart.products)  | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
        </div>
        <div class="infoCart-item bor">
          <span class="text bold size-16">Total</span>
          <span class="text segoe size-16">{{ getTotal(cart.products)  | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
        </div>
      </div>
      <div class="p-10 clearCart" v-if="Object.keys(cart).length > 0 && Object.keys(cart.products).length > 0">
        <!-- <b-button type="is-danger" size="is-small" icon-left="trash" icon-pack="fas" class="m-left-10 btn-precompro" @click="deleteCartAll()">Vaciar Carrito</b-button> -->
        <b-button class="btn-finalizar-pedido" @click="viewCart()"><span class="text bold sie-18">Finalizar tu pedido</span></b-button>
      </div>
      <div class="p-10 minimunOrder" v-if="(Object.keys(cart).length > 0 && Object.keys(cart.products).length > 0) && getTotal(cart.products) < getVendor.minPriceForOrder">
        <p class="text bold">Mínimo para pedido</p>
        <b-progress :value="getTotal(cart.products)" :max="parseInt(getVendor.minPriceForOrder)" show-value>{{getTotal(cart.products) | currency('$ ', 0, { thousandsSeparator: ',' }) }} / {{getVendor.minPriceForOrder | currency('$ ', 0, { thousandsSeparator: ',' })}}</b-progress>
      </div>
    </div>

</template>

<script>
import LocalStorage from 'store';
import { mapGetters } from 'vuex';
import EndOrder from '@/components/modeCarta/EndOrder';
import _ from 'lodash';
export default {
  props: ['statusShow'],
  data () {
    return {
      isOpen: true,
      loading: false,
      viewCartList: false,
      closeAnimated: false,
      cart: [],
      messDelete: false,
      messDeletePro: []
    };
  },
  mounted () {
    this.cart = this.getCart;
  },
  computed: {
    ...mapGetters([
      'getVendor',
      'getCart'
    ])
  },
  methods: {
    isDeals (data, type) {
      let show = false;
      Object.values(data).map(item => {
        if (item.type === type) {
          show = true;
        }
      });
      return show;
    },
    shortText (text) {
      if (text) {
        if (text.length > 25) {
          return text.substr(0, 25) + '...';
        } else {
          return text;
        }
      } else {
        return 'N/A';
      }
    },
    deleteProductCart (id, p, product) {
      this.loading = true;
      this.messDeletePro = product;
      const thes = this;
      const data = {};
      data.index = p;
      this.$http.put('marketplace/client/cart/' + LocalStorage.get('domicompro-uid-user') + '/item/' + id, data).then(({ data }) => {
        this.$store.commit('setCart', data.data);
        this.loading = false;
        this.messDelete = true;
        setTimeout(function () {
          thes.messDelete = false;
          thes.messDeletePro = [];
        }, 3000);
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    deleteCartAll () {
      this.loading = true;
      this.$http.delete('marketplace/client/cart/delete/all/' + LocalStorage.get('domicompro-uid-user')).then(({ data }) => {
        this.$store.commit('setCart', data.data);
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: 'Carrito eliminado',
          position: 'is-top',
          type: 'is-success'
        });
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    updateCart (product, type, index) {
      if (this.validateProductPromo(product) || type === 'min') {
        this.loading = true;
        if (type === 'max') {
          product.quantity += 1;
        }
        if (type === 'min') {
          product.quantity -= 1;
        }
        product.index = index;
        this.$http.put('marketplace/client/cart/update/' + LocalStorage.get('domicompro-uid-user') + '/item/' + product._id, product).then(({ data }) => {
          this.$store.commit('setCart', data.data);
          this.$buefy.toast.open({
            duration: 1000,
            message: 'Producto actualizado',
            position: 'is-top',
            type: 'is-success'
          });
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          this.$buefy.toast.open({
            duration: 1000,
            message: this.$parent.listErrors(err.request.response, err.request.status),
            position: 'is-top',
            type: 'is-danger'
          });
        });
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'No se pueden agregar más productos de promoción al carrito',
          position: 'is-top',
          type: 'is-danger'
        });
      }
    },
    validateProductPromo (product) {
      const cartProducts = this.getCart.products;
      let cartLength = 0;
      if (cartProducts !== undefined) {
        cartLength = cartProducts.length;
      }
      const maxPromo2x1 = this.getVendor.maxPromo2x1;
      const maxPromo3x2 = this.getVendor.maxPromo3x2;
      let promo2x1 = 0;
      let promo3x2 = 0;
      if (cartLength === 0 ||
             product.deal.length === 0 ||
             (maxPromo2x1 === undefined && product.deal[0].type === '2x1') ||
             (maxPromo3x2 === undefined && product.deal[0].type === '3x2')
      ) {
        return true;
      } else {
        _.forEach(cartProducts, function (pd) {
          if (Object.keys(pd.deal).length > 0) {
            if (pd.deal[0].type === '2x1') {
              promo2x1 = promo2x1 + pd.quantity;
            }
            if (pd.deal[0].type === '3x2') {
              promo3x2 = promo3x2 + pd.quantity;
            }
          }
        });
        const total2x1 = Math.floor(promo2x1 / 1);
        const total3x2 = Math.floor(promo3x2 / 1);
        if (total2x1 < maxPromo2x1 && product.deal[0].type === '2x1') {
          return true;
        }
        if (total3x2 < maxPromo3x2 && product.deal[0].type === '3x2') {
          return true;
        }
      }
      return false;
    },
    deleteMerchantCart (id) {
      this.loading = true;
      const data = {};
      data.id = id;
      this.$http.put('marketplace/client/cart/' + LocalStorage.get('domicompro-uid-user') + '/merchant/delete', data).then(({ data }) => {
        this.$store.commit('setCart', data.data);
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: 'Carrito eliminado',
          position: 'is-top',
          type: 'is-success'
        });
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    getTotal (products) {
      let value = 0;
      Object.values(products).map((item) => {
        if (item.percentOff) {
          value += item.quantity * (item.price - (item.price * item.percentOff / 100));
        } else {
          value += (item.price * item.quantity);
        }
      });
      return value;
    },
    getSubTotal (products) {
      let value = 0;
      Object.values(products).map((item) => {
        value += (item.price * item.quantity);
      });
      return value;
    },
    getTotalDescuento (products) {
      let value = 0;
      Object.values(products).map((item) => {
        if (item.percentOff) {
          value += item.quantity * (item.price * item.percentOff / 100);
        }
      });
      return value;
    },
    viewCart () {
      this.$buefy.modal.open({
        parent: this,
        component: EndOrder,
        hasModalCard: true,
        fullScreen: true,
        canCancel: false,
        scroll: 'keep'
      });
      this.$parent.close();
    },
    getTotalProduct (product) {
      let priceComplete = product.price;
      if (product.percentOff) {
        priceComplete = product.price - (product.price * product.percentOff / 100);
      }
      let total = priceComplete * product.quantity;
      if (product.options.extra > 0) {
        total += product.options.extra;
      }
      return total;
    },
    updateCartItem (product, pIndex) {
      const data = {};
      product.edit = true;
      data.product = product;
      data.index = pIndex;
      this.$store.commit('setProductInfo', data);
    }
  },
  watch: {
    statusShow () {
      this.viewCartList = this.statusShow;
    },
    getCart () {
      this.cart = this.getCart;
    }
  }
};
</script>

<style scoped lang="scss">
  .promoRight {
    float: right;
    right: 10px;
    position: absolute;
  }
  svg {
    margin-right: 5px!important;
  }
  .cart-header {
    padding: 20px;
    padding-right: 40px;
    position: relative;
    z-index: 2147483647;
    background-color: inherit;
    margin-bottom: 10px;
    &-title {
      font-size: 18px;
      color: #000;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-btn-close {
      position: absolute;
      right: 9px;
      top: 8px;
      font-size: 22px;
      color: #000;
      border: none;
      background-color: transparent;
      cursor: pointer;
      &:hover {
        box-shadow: none;
        -webkit-box-shadow: none;
        border-color: transparent;
        transform: translateY(-1px);
      }
      &:focus{
        box-shadow: none;
        -webkit-box-shadow: none;
        border-color: transparent;
      }
      &:focus:not(:active) {
        box-shadow: none;
        -webkit-box-shadow: none;
        border-color: transparent;
      }
      &:active {
        box-shadow: none;
        -webkit-box-shadow: none;
        border-color: transparent;
      }
    }
  }
  .cart-content {
    padding: 5px 25px;
    height: calc(100% - 100px);
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.bar-progre{
      height: calc(100% - 125px);
    }
  }
  .product-title {
    font-size: 14px;
  }
  .product-description {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
    -webkit-line-clamp: 2;
    font-size: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .bor {
    margin-top: 10px;
    padding-top: 5px;
  }

  aside.aside {
    width: 5rem;
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 40;
    height: calc(100% - 60px);
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #ffffff; /*#051d38;*/
    //background-image: -webkit-gradient(linear,left top,right top,from(#051d38),to(#1e344c));
    //background-image: linear-gradient(90deg,#051d38,#1e344c);
  }

  aside.aside.is-placed-right {
    -webkit-transition: right .25s ease-in-out 50ms;
    transition: right .25s ease-in-out 50ms;
    width: 20rem;
    display: block;
    left: auto;
    border-left: 1px solid #f8f8f8;
    right: -20rem;
    background: #ffffff; /*#051d38;*/
    color: #151E26;
    animation:  rightToLeft 1.5s;
  }

   aside.aside.is-placed-left {
    -webkit-transition: left .25s ease-in-out 50ms;
    transition: left .25s ease-in-out 50ms;
    width: 20rem;
    display: block;
    border-left: 1px solid #f8f8f8;
    left: inherit;
    right: -320px!important;
    background: #ffffff; /*#051d38;*/
    color: #151E26;
  }

  .close-animated{
    animation:  leftToRight 2s;
  }

  #cartEmpty {
    color: #151E26!important;
  }

  #productName {
    color: #000000!important;
  }

  aside.aside.is-placed-right.is-placed-right-active {
    right: 0px;
  }

  aside.aside .aside-container {
    position: relative;
    height: 100%;
    padding: 0 0 155px 0;
    background: #fff;
  }

  .ps {
    overflow: auto;
    overflow-anchor: none;
    height: calc(100% - 120px);
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
  }

  .is-black {
    color: rgba(0, 0, 0, 0.7607843137254902)!important
  }

  .is-white-flex {
    display: flex;
  }
  .cont-title-product{
    display: flex;
    border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
    padding-bottom: 5px;
    justify-content: space-between;
  }
  .p-top-5 {
    padding-top: 5px;
  }

  .p-1 {
    padding: 1px;
  }

  .center-div {
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: column;
    height: 100%;
    background: #fff;
  }

  .advance {
    // justify-content: center!important;
    // align-items: center!important;
    display: flex!important;
  }

  .p-10 {
    padding: 10px 0px;
    margin: 0 25px;
    text-align: center;
    &.hight-100{
      height: 100%;
    }
  }

  .clearCart {
    position: absolute;
    bottom: 1px;
    background-color: #ffffff; /*#051d38;*/
    width: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
  }
  .minimunOrder{
    position: absolute;
    bottom: 166px;
    background-color: #ffffff; /*#051d38;*/
    max-width: 20rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    width: 19.9rem;
    flex-direction: column;
    .progress-wrapper{
      width: 100%;
    }
  }
  .infoCart {
    position: absolute;
    bottom: 70px;
    background-color: #ffffff; /*#051d38;*/
    width: calc(100% - 50px);
    align-items: center;
    border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
    border-top: 1.5px solid rgba(207, 205, 205, 0.6);
    &-item {
      display: flex;
      justify-content: space-between;
    }
  }

  .itemCart {
    display: flex;
    justify-content: center;
    align-content: center;
    border-color: #151E26;
    &.directionCol {
      flex-direction: column;
    }
  }
  .flex-col {
    flex-direction: column;
  }
  #plus {
    padding-left: 10px;
    border-radius: 4px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    .svg-inline--fa{
      height: 12px;
    }
    &:hover {
      transform: translateY(-1px);
    }
    &:active {
      transform: translateY(1px);
    }
  }

  #minus {
    padding-left: 10px;
    border-radius:4px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    .svg-inline--fa {
      height: 12px;
    }
    &:hover {
      transform: translateY(-1px);
    }
    &:active {
      transform: translateY(1px);
    }
  }

  #quantity {
    width: 100%;
    background-color: #ffffff;
    color: #000000;
    justify-content: center;
    align-content: center;
    display: flex;
    user-select: none;
    pointer-events: none;
  }

  .cartPrice {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'bold';
    font-size: 13px;
     margin-right: 4px;
    &-discountPercent {
      font-size: 11px;
      color: #fff;
      padding: 1px 3px;
      text-align: center;
      border-radius: 8px;
      font-size: 10px;
      background-color: #ff2425;
      margin-left: 3px;
    }
  }

  .textLine {
    color: #9faab7;
    text-decoration: line-through;
    font-size: 12px;
  }

  .is-64x64 {
    height: 82px!important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image img {
    max-height: 80px;
  }

  .btn-right {
    float: right;
    right: 5px;
    position: absolute;
    &:hover {
      transform: translateY(-1px);
    }
    &:active {
      transform: translateY(1px);
    }
  }

  .carousel-footer {
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    color: #4a4a4a;
  }

  .m-left-5 {
    margin-left: 5px;
  }

  .m-top-5 {
    margin-top: 5px;
  }

  .imagenVendor {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-shadow: 0 6px 10px 0 #e5edef;
    background-position: center;
    background-size: cover;
  }

  .nameVendor {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
  }

  .div-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 10px;
    color: #000000;
  }

  .article {
    padding: 0.3rem!important;
  }

  .btn-vaciar {
    background-color: rgb(162, 42, 22) !important;
    border-radius: 0px !important;
    &:hover {
      transform: translateY(-1px);
    }
    &:active {
      transform: translateY(1px);
    }
    &.padding-lateral {
      padding: 0 20px;
    }
    &.green {
      background-color:#559A70 !important ;
    }
  }

  .card .media:not(:last-child) {
    margin-bottom: 0.5rem!important;
  }
  // .media-content .content {
  //   max-width: calc(20rem - 128px);
  // }
  .media + .media {
    border-top: inherit;
    margin-top: 0.5rem!important;
    padding-top: 0.5rem!important;
  }
  .media{
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
  }
  .text{
    &.ellip-desc{
    text-overflow: ellipsis;
    overflow: hidden;
    width: 600px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    }
  }
  .addons-cont{
    display: flex;
    justify-content: space-between;
  }
  .btn-minus-plus{
    display: flex;
    justify-content: center;
    align-content: center;
    border: 1p;
    border: 1px solid rgba(51, 51, 51, 0.35);
    border-radius: 4px;
  }
  .btn-finalizar-pedido{
    background: var(--buttonBackground);
    color: var(--buttonTextColor) !important;
    .text{
          color: var(--buttonTextColor) !important;
    }
    width: 100%;
    height: 45px;
    max-width: 350px;
  }
  .btn-menu{
    background: #CDD500;
    width: calc(100% - 50px);
    height: 45px;
    max-width: 350px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -40px);
  }
</style>
