<template>
  <div class="card minWidth" id="modal-product">
    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false">
      <b-icon icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>
    <b-message id="messAditions" :active.sync="messAdition">
      <div class="cont-info">
      <span class="text bold size-20" style="display: flex;align-items: center;"><i class="icon-mks icon_alert red" style="margin-right:10px"></i> Adiciones</span>
      <span class="text size-15">Por favor seleccione adiciones antes de continuar</span>
       </div>
    </b-message>
    <div class="card-content productView">
      <div class="cont-view-product">
        <a href="javascript:void(0)" class="close-cont-info" @click="$parent.close()">
          <i class="icon-mks icon_close black" style="height: 15px;width: 15px;" id="iconClose"></i>
        </a>
        <div class="cont-img-product">
          <div class="content-img" :style="{'background-image': 'url(' + product.image + ')'}">
            <i v-if="product.image == undefined || product.image == null" class="icon-mks icon_image_broken gray tam-100"></i>
          </div>
          <span class="category-float">{{ catName }}</span>
        </div>
        <div class="cont-info-product">
          <div class="info-product-modal">
            <span class="text bold size-23 text-config">{{ product.name }}</span>
          </div>
          <div class="product-toppings-content">
            <p id="product-view-description">{{ product.description}}</p>
            <span class="text bold size-18 text-config" v-if="!product.specs">{{ product.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
            <div class="product-toppings-relative" v-for="(subproduct, sp) in product.specs" :key="'subproduct#' + sp">
              <div class="product-item">
                  <div class="cont-title-subproduct">
                     <span class="text bold size-14 text-config" :title="subproduct.name">{{ subproduct.name }}</span>
                     <span class="text size-12 text-config">Escoge que alimentos deseas incluir</span>
                  </div>
                <div class="product-item-labels">
                  <span class="text size-12 subProductCont mr-5" v-if="subproduct.required">Obligatorio</span>
                  <span class="text size-12 subProductCont" :class="{'mr-5': (!subproduct.required)}" v-if="subproduct.min > 0"> {{ subproduct.min }} Min.</span>
                  <span class="text size-12 subProductCont" v-if="subproduct.max > 1"> {{ subproduct.max }} Max.</span>
                </div>
              </div>
              <div class="topping-content" v-if="subproduct.max == 1">
                <div :class="{'sinStock': item.isActive == 0}" class="input-radio" v-for="(item, spi) in subproduct.items" :key="'items#' + spi" :id="subproduct.name +'-'+ product.name"  @click="addItem(subproduct.name, subproduct.max, item, 'unique')">
                  <div class="topping-restaurant rest-radio" :id="'product#' + subproduct.name + '-' + item.name" :class="{active: item.name == selected}">
                    <label>
                      <span class="text segoe text-config" :class="{'not-price': item.price == undefined}">{{ item.name }}</span>
                      <span class="price" v-if="item.price">+{{ item.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="topping-content" v-if="subproduct.max > 1">
                <div :class="{'sinStock': item.isActive == 0}" class="input-radio" v-for="(item, spi) in subproduct.items" :key="'items#' + spi" :id="subproduct.name + '-' + product.name">
                  <div class="topping-restaurant rest-radio" :id="'product#' + subproduct.name + '-' +  item.name" @click="addItem(subproduct.name, subproduct.max, item, 'multiple')">
                    <label>
                      <span class="text segoe text-config" :class="{'not-price': item.price == undefined}">{{ item.name }}</span>
                      <span class="price" v-if="item.price">+{{ item.price }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="textarea-cont">
              <span class="text bold size-18 margin-bot-10 text-config" title="Instrucciones adicionales">¿Algún comentario adicional?</span>
              <div id="topping-instructions">
                <textarea cols="30" id="comments" rows="10" placeholder="Personaliza tu pedido" v-model="observation"></textarea>
              </div>
            </div>
          </div>
          <div class="product-operator-footer">
            <button class="button-agregar" type="button" @click="validateItemCart(product)">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                <path d="M9.16667 4.16667H5.83333V0.833333C5.83333 0.61232 5.74554 0.400358 5.58926 0.244078C5.43297 0.0877975 5.22101 0 5 0C4.77899 0 4.56702 0.0877975 4.41074 0.244078C4.25446 0.400358 4.16667 0.61232 4.16667 0.833333V4.16667H0.833333C0.61232 4.16667 0.400358 4.25446 0.244078 4.41074C0.0877975 4.56702 0 4.77899 0 5C0 5.22101 0.0877975 5.43297 0.244078 5.58926C0.400358 5.74554 0.61232 5.83333 0.833333 5.83333H4.16667V9.16667C4.16667 9.38768 4.25446 9.59964 4.41074 9.75592C4.56702 9.9122 4.77899 10 5 10C5.22101 10 5.43297 9.9122 5.58926 9.75592C5.74554 9.59964 5.83333 9.38768 5.83333 9.16667V5.83333H9.16667C9.38768 5.83333 9.59964 5.74554 9.75592 5.58926C9.9122 5.43297 10 5.22101 10 5C10 4.77899 9.9122 4.56702 9.75592 4.41074C9.59964 4.25446 9.38768 4.16667 9.16667 4.16667Z"/>
              </svg>
              <span class="text">{{ (edit) ? 'Actualizar' : 'Agregar' }}</span>
            </button>
            <span class="button-agregar-total">{{ extra | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalStorage from 'store';
import _ from 'lodash';
import { mapGetters } from 'vuex';
export default {
  props: ['product', 'index', 'catName'],
  data () {
    return {
      loading: false,
      quantity: 1,
      extra: 0,
      observation: null,
      price: 0,
      selected: [],
      errorImg: false,
      edit: false,
      specsObli: false,
      messAdition: false
    };
  },
  mounted () {
    this.price = this.product.price;
    if (this.product.edit) {
      this.selectedProducts(this.product.options.products);
      this.edit = true;
      this.selected = this.product.options.products;
      this.quantity = this.product.quantity;
      this.observation = this.product.options.observation;
    }
    if (this.product.specs !== undefined) {
      if (Object.keys(this.product.specs).length === 0) {
        this.specsObli = true;
      }
    }
  },
  computed: {
    ...mapGetters([
      'getVendor',
      'getCart'
    ])
  },
  methods: {
    error (img) {
      this.errorImg = true;
    },
    selectedProducts (products) {
      products.map((item) => {
        const key = item.name;
        item.items.map((data) => {
          const dom = document.getElementById('product#' + key + '-' + data.item);
          dom.classList.add('active');
        });
      });
    },
    addQuantity (product) {
      if (this.validateProductPromo(product)) {
        this.quantity += 1;
      }
    },
    removeQuantity (product) {
      if (this.validateProductPromo(product)) {
        this.quantity -= 1;
      }
    },
    validateProductPromo (product) {
      const cartProducts = this.getCart.products;
      let cartLength = 0;
      if (cartProducts !== undefined) {
        cartLength = cartProducts.length;
      }
      const maxPromo2x1 = this.getVendor.maxPromo2x1;
      const maxPromo3x2 = this.getVendor.maxPromo3x2;
      let promo2x1 = 0;
      let promo3x2 = 0;
      if (cartLength === 0 || product.deal.length === 0 || (maxPromo2x1 === undefined && product.deal[0].type === '2x1') || (maxPromo3x2 === undefined && product.deal[0].type === '3x2')) {
        return true;
      } else {
        _.forEach(cartProducts, function (pd) {
          if (Object.keys(pd.deal).length > 0) {
            if (pd.deal[0].type === '2x1') {
              promo2x1 = promo2x1 + pd.quantity;
            }
            if (pd.deal[0].type === '3x2') {
              promo3x2 = promo3x2 + pd.quantity;
            }
          }
        });
        const total2x1 = Math.floor(promo2x1 / 1);
        const total3x2 = Math.floor(promo3x2 / 1);
        if (total2x1 < maxPromo2x1 && product.deal[0].type === '2x1') {
          return true;
        }
        if (total3x2 < maxPromo3x2 && product.deal[0].type === '3x2') {
          return true;
        }
      }
      return false;
    },
    addItem (key, max, item, type) {
      const dom = document.getElementById('product#' + key + '-' + item.name);
      if (type === 'unique') {
        const pos = _.findIndex(this.selected, { name: key });
        if (pos >= 0) {
          const exists = _.findIndex(this.selected[pos].items, { item: item.name });
          if (exists >= 0) {
            if (this.selected[pos].items[exists].price > 0) {
              this.extra -= parseInt(this.selected[pos].items[exists].price);
            }
            this.selected.splice(pos, 1);
            dom.classList.remove('active');
          }
        } else {
          const info = {};
          info.name = key;
          info.items = [];
          const data = {
            item: item.name,
            price: item.price || 0
          };
          if (item.price > 0) {
            this.extra += parseInt(item.price);
          }
          info.items.push(data);
          this.selected.push(info);
          dom.classList.add('active');
        }
      }

      if (type === 'multiple') {
        const pos = _.findIndex(this.selected, { name: key });
        if (pos >= 0) {
          const exists = _.findIndex(this.selected[pos].items, { item: item.name });
          if (exists >= 0) {
            if (this.selected[pos].items[exists].price > 0) {
              this.extra -= parseInt(this.selected[pos].items[exists].price);
            }
            if (this.selected[pos].items.length === 1) {
              this.selected.splice(pos, 1);
            } else {
              this.selected[pos].items.splice(exists, 1);
            }
            dom.classList.remove('active');
          } else {
            if (this.selected[pos].items.length < max) {
              const data = {
                item: item.name,
                price: item.price || 0
              };
              if (item.price > 0) {
                this.extra += parseInt(item.price);
              }
              this.selected[pos].items.push(data);
              dom.classList.add('active');
            } else {
              this.$buefy.toast.open({
                duration: 800,
                message: 'llegó al máximo de adiciones',
                position: 'is-top',
                type: 'is-danger'
              });
            }
          }
        } else {
          const info = {};
          info.name = key;
          info.items = [];
          const data = {
            item: item.name,
            price: item.price || 0
          };
          if (item.price > 0) {
            this.extra += parseInt(item.price);
          }
          info.items.push(data);
          this.selected.push(info);
          dom.classList.add('active');
        }
      }
    },
    validateItemCart (product) {
      const radio = [];
      this.specsObli = false;
      if (product.specs !== undefined) {
        if (product.specs.length > 0) {
          product.specs.map((item) => {
            const vari = [];
            if (item.required === true) {
              vari.name = item.name;
              vari.max = item.max;
              vari.min = item.min || 0;
              radio.push(vari);
            }
          });
        } else {
          this.specsObli = true;
        }
      }
      if (this.selected.length > 0) {
        const total = radio.length;
        let sig = 0;
        radio.map((item, index) => {
          const indexP = _.findIndex(this.selected, { name: item.name });
          if (indexP >= 0) {
            if (item.min > 0) {
              if (this.selected[indexP].items.length >= item.min) {
                sig++;
              }
            } else if (item.max > 0) {
              if (this.selected[indexP].items.length <= item.max) {
                sig++;
              } else {
                if (item.min === 0) {
                  sig++;
                }
              }
            }
          }
        });
        if (total > 0 && total === sig) {
          this.specsObli = true;
        } else {
          this.specsObli = false;
        }
      }
      if (radio.length === 0) {
        this.specsObli = true;
      }
      if (this.specsObli) {
        if (Object.keys(product.deal).length > 0 && Object.keys(this.selected).length > 0) {
          if (product.deal[0].type === '2x1') {
            const dosXuno = _.orderBy(this.selected[0].items, ['price'], ['desc']);
            const p = dosXuno[1].price;
            this.extra -= p;
            dosXuno[1].price = 0;
          }
          if (product.deal[0].type === '3x2') {
            const tresXdos = _.orderBy(this.selected[0].items, ['price'], ['desc']);
            const p = tresXdos[2].price;
            this.extra -= p;
            tresXdos[2].price = 0;
          }
        }
        let addOrUpdated = true;
        addOrUpdated = this.validateProductPromo(product);
        if (addOrUpdated) {
          if (product.edit) {
            const data = {};
            delete product.edit;
            product.options.extra = this.extra;
            product.options.observation = this.observation;
            product.quantity = this.quantity;
            data.product = product;
            data.index = this.index;
            data.products = this.selected;
            this.updateToCartProduct(data);
          } else {
            this.addToCartProduct(product);
          }
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'No se pueden agregar más productos de promoción al carrito',
            position: 'is-bottom',
            type: 'is-danger'
          });
        }
      } else {
        const thes = this;
        this.messAdition = true;
        setTimeout(function () {
          thes.messAdition = false;
        }, 3000);
      }
    },
    addToCartProduct (product) {
      const info = {};
      const data = {};
      const oldPrice = parseInt(product.price);
      data.products = this.selected;
      data.extra = this.extra;
      data.observation = this.observation;
      product.options = data;
      product.quantity = this.quantity;
      info.sessionId = LocalStorage.get('domicompro-uid-user');
      info.productId = product._id;
      const price = parseInt(product.price) + this.extra;
      product.price = price;
      info.products = product;
      this.loading = true;
      this.$http.post('/marketplace/client/cart/add', info).then(({ data }) => {
        this.loading = false;
        product.price = oldPrice;
        this.$store.commit('setCart', data.data);
        this.$parent.close();
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    updateToCartProduct (info) {
      info.product.options.observation = this.observation;
      this.loading = true;
      this.$http.put('/marketplace/client/cart/update/' + LocalStorage.get('domicompro-uid-user') + '/product/' + info.product._id, { product: info.product, index: info.index }).then(({ data }) => {
        this.loading = false;
        this.$store.commit('setCart', data.data);
        this.$buefy.toast.open({
          duration: 1000,
          message: 'Producto actualizado al carrito',
          position: 'is-top',
          type: 'is-success'
        });
        this.$parent.close();
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
#modal-product {
  padding: 10px;
  background-color: var(--backCardProduct);
  .text-config {
    color: var(--textColorDescriptionCard);
  }
  .card-content {
    padding: unset;
  }
  .productView {
    height: 100%;
    .cont-view-product {
      background-color: var(--backCardProduct);
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-direction: column;
      font-size: 14px;
      width: 100%;
      height: 100%;
      text-align: left;
    }
    .cont-img-product {
      flex: .9;
      min-width: 300px;
      max-height: 100px;
      border-radius: 8px;
      position: relative;
      .category-float {
        width: 74px;
        height: 18px;
        border-radius: 2px;
        background: var(--textColorDescriptionCard);
        color: var(--backCardProduct);
        font-family: 'Open Sans';
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        position: absolute;
        top: 9px;
        left: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .content-img {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px 10px 0px 0px;
      background-size: cover;
      background-position: center center;
      background-color: lightgray;
      background-position: 50%;
      background-repeat: no-repeat;
      background-color: var(--backCardProduct);
      height: 100px;
      max-height: 100px;
      font-size: 0;
    }
    .cont-view-product .cont-img-product .content-img img {
      max-width: 100%;
      max-height: 100%;
      margin: 0;
    }
    .cont-view-product .cont-img-product .content-img img {
      max-width: 100%;
      max-height: 100%;
      margin: 0;
    }
    .cont-info-product {
      width: auto;
      height: 100%;
      overflow: hidden;
      padding-top: 10px;
      display: flex;
      flex-direction: column
    }
    .info-product-modal {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .info-product-modal h2 {
      font-size: 28px;
      line-height: 1.25;
      font-weight: 400;
    }
    .product-toppings-content {
      -webkit-overflow-scrolling: touch;
      width: 100%;
      flex: 1;
      max-height: 450px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .product-toppings-relative {
      position: relative;
    }
    .product-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      min-height: 40px;
      margin-top: 10px;
      padding: 5px 10px;
      background: var(--backCardProduct);
    }
    .product-item-labels {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
    }
    .iconf {
      font-family: icomoon!important;
      speak: none;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: var(--buttonBackground);
      margin-left: 10px;
    }
    .topping-content {
      padding: 5px 10px;
    }
    .input-radio {
      padding: 1px 0;
      position: relative;
      &.sinStock {
        user-select: none;
        &::after{
          content: "Sin stock";
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-weight: 700;
          position: absolute;
          width: 100%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
          background: rgba(170, 170, 170, 0.75);
          border-radius: 2px;
          z-index: 1;
        }
        }
    }
    .topping-restaurant.rest-radio, .topping-restaurant.rest-checkbox {
      display: inline-block;
      vertical-align: middle;
      margin-right: 25px;
      width: 100%;
      margin-bottom: 0;
      height: auto;
    }
    .topping-restaurant.rest-radio {
      transition: all .3s linear;
    }
    .topping-restaurant.rest-radio label {
      font-size: 0;
      position: relative;
      cursor: pointer;
      display: block;
    }
    .topping-restaurant.rest-radio label::before {
      content: "";
      width: 16px;
      height: 16px;
      border: 1px solid #C1C1C1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      transition: all .4s;
      cursor: pointer;
      border-radius: 50%;
      background: 0 0;
    }
    .topping-restaurant.rest-radio label span.not-price {
      width: 100%;
    }
    .topping-restaurant.rest-radio label::after {
      transition: all .3s linear;
      content: "";
      width: 6px;
      height: 6px;
      transform: scale(0);
      position: absolute;    transform: scale(1);
      right: 5px;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 50%;
    }
    .topping-restaurant.rest-checkbox label p, .topping-restaurant.rest-checkbox label span, .topping-restaurant.rest-radio label p, .topping-restaurant.rest-radio label span {
      width: calc(100% - 74px);
      line-height: 18px;
      font-size: 14px;
      display: inline-block;
      vertical-align: middle;
    }
    .required {
      color: #59626d;
      font-size: 12px;
      display: inline-block;
      float: right;
      background: #e6e6e1;
      padding: 5px 10px;
      border-radius: 14px;
      font-weight: 100;
    }
    .product-toppings-content h3 {
      color: #332927;
      width: 100%;
    }
    .topping-description {
      font-size: 16px;
      padding-bottom: 5px;
      text-align: left;
      font-family: Poppins-SemiBold,Helvetica,Arial,sans-serif;
      margin: 25px 0 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .topping-description {
      font-weight: 700;
    }
    #product-view-description {
      font-size: 12px;
      font-weight: 400;
      color: var(--textColorDescriptionCard);
      padding: 5px 10px;
    }
    .product-operator-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
    .product-operator-footer .button-add {
      width: 190px;
      bottom: 0px;
      height: 25px;
      max-width: 60%;
      &.disabled{
        opacity: .6;
      }
    }
    .product-operator-footer .counter-product-light {
      float: left;
    }
    .counter-product-light>button:disabled {
      opacity: 0.5;
    }
    .counter-product-light>div {
      font-size: 14px;
      text-transform: lowercase;
      font-family: Poppins-SemiBold,Helvetica,Arial,sans-serif;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      color: #332927;
    }
    .counter-product-light {
      width: 105px;
      max-width: 40%;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid #CFCDCD;
      border-radius: 5px;
    }
    .counter-product-light>button {
      border: inherit;
      color: #ffffff;
      background-color: #151e26;
      border-radius: 10px;
      width: 34px;
      height: 34px;
      font-family: Poppins-SemiBold,Helvetica,Arial,sans-serif;
      transition: all .3s linear;
      cursor: pointer;
    }
    .counter-product-light>button:disabled:hover {
      cursor: inherit;
      color: #ffffff;
      background-color: #151e26;
    }
    .counter-product-light>button:hover {
      color: #ffffff;
      background-color: #151e26;
    }
    .product-operator-footer .button-add button {
      color: #ffffff;
      border-radius: 10px;
      text-align: left;
      width: 100%;
    }
    .primary-button-filled {
      background-color: #151e26;
      border-radius: 10px;
      font-family: Poppins-Regular,Helvetica,Arial,sans-serif;
      color: #ffffff;
      padding: 8.5px 14px;
      text-align: center;
      cursor: pointer;
    }
    .primary-button-filled svg {
      margin-right: 5px;
    }
    .product-operator-footer .button-add button small {
      float: right;
    }
    .close-cont-info {
      color: #332927;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      position: absolute;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      right: 25px;
      border-radius: 50%;
      cursor: pointer;
      z-index: 2;
      font-size: 8px;
      top: 25px;
    }
    #iconClose {
      color: #151e26;
      font-weight: 700;
      font-size: 16px;
      background: var(--buttonBackground);
      letter-spacing: 0;
    }
  }
  .minWidth {
        overflow: hidden;
        border-radius: 10px;
        height: 100%;
  }

  .card-content {
    background-color: #ffffff;
  }
  button {
    box-shadow: none;
    outline: none;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .topping-restaurant.rest-checkbox label, .topping-restaurant.rest-radio label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    line-height: 1.71;
    color: #7d7d7d;
    padding-right: 30px;
    font-family: Poppins-Regular,Helvetica,Arial,sans-serif;
    margin: 0;
    text-align: left;
  }
  .topping-restaurant.rest-checkbox label {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .topping-restaurant.rest-checkbox label::before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 1px;
    z-index: 0;
    border: 2px solid #e5edef;
    width: 15px;
    height: 15px;
    border-radius: 4px;
  }
  .topping-restaurant.rest-checkbox label::after {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 8px;
    z-index: 1;
    left: 6px;
    width: 5px;
    height: 7px;
    border: solid var(--buttonBackground);
    border-width: 0 3px 3px 0;
    border-left-color: #fff;
    transform: rotate(45deg);
    box-shadow: 1 0 0 1px #fff;
    background: #fff;
  }
  .topping-restaurant.rest-checkbox label p.price, .topping-restaurant.rest-checkbox label span.price, .topping-restaurant.rest-radio label p.price, .topping-restaurant.rest-radio label span.price {
    color: #9faab7;
    text-align: right;
    width: 66px;
  }
  .topping-restaurant.active.rest-checkbox label::before {
    border-color: var(--buttonBackground);
    background-color: var(--buttonBackground);
  }
  .topping-restaurant.rest-checkbox label:hover::after {
    opacity: 1;
    visibility: visible;
    border-color: var(--buttonBackground);
  }
  .topping-restaurant.active.rest-checkbox label::after {
    opacity: 1;
    visibility: visible;
    border-color: #fff #fff #fff transparent;
    box-shadow: 1px 0 0 1px transparent;
    background: 0 0;
  }
  .topping-restaurant.active.rest-radio label::after {
    transform: scale(1.5);
    background: var(--textColorDescriptionCard);
  }
  #topping-instructions textarea {
    padding: 10px;
    width: 100%;
    resize: none;
    height: 70px;
    border: 1px solid var(--textColorDescriptionCard);
    background: var(--backCardProduct);
    font-size: 12px;
    border-radius: 4px;
    font-family: 'segoe';
    color: var(--textColorDescriptionCard);
    display: block;
    margin: 10px 0;
    outline: unset;
    &::placeholder {
      color: var(--textColorDescriptionCard);
    }
  }
  .cont-title-subproduct{
      display: flex;
      flex-direction: column;
  }
  .text{
      &.subProductCont{
        border: 1px solid rgba(112, 112, 112, 0.3);
        padding: 2px 5px;
        background-color: var(--textColorDescriptionCard);
        color: var(--backCardProduct)
      }
      &.numCant{
      display: flex;
      align-items: center;
      }
  }
  .mr-5 {
    margin-right: 5px;
  }
  .textarea-cont{
      margin: 10px 0;
      padding: 5px 10px;
  }
  .btn-addons{
      height: 35px;
      width: 30px;
      position: relative;
      cursor: pointer;
      background: var(--buttonBackground);
      &.left {
        border-radius: 4px 0 0 4px;
      }
      &.right {
        border-radius: 0 4px 4px 0;
      }
      #icon-addons{
      color: var(--buttonTextColor);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      }
  }
  .button-agregar{
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    height: 25px;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--textColorDescriptionCard);
    border-color: var(--textColorDescriptionCard);
    margin-right: 40px;
    svg {
      margin-right: 16px;
      path {
        fill: var(--backCardProduct);
      }
    }
    span {
      text-align: center;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--backCardProduct);
    }
    &-total {
      color: var(--textColorDescriptionCard);
      text-align: center;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
</style>
