<template>
  <div class="card minWidth">
    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false">
      <b-icon icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>
    <b-message id="messAditions" :active.sync="messAdition">
      <div class="cont-info">
      <span class="text bold size-20" style="display: flex;align-items: center;"><i class="icon-mks icon_alert red" style="margin-right:10px"></i> Adiciones</span>
      <span class="text size-15">Por favor seleccione adiciones antes de continuar</span>
       </div>
    </b-message>
    <div class="card-content productView">
      <div class="cont-view-product">
        <a href="javascript:void(0)" class="close-cont-info" @click="$parent.close()"><i class="icon-mks icon_close black" style="height: 15px;width: 15px;" id="iconClose"></i></a>
        <div class="cont-img-product">
          <div class="content-img">
            <img v-if="(product.image !== undefined || product.image !== null) && !errorImg" :src="product.image" @error="error(this)">
            <i v-if="product.image == undefined || product.image == null" class="icon-mks icon_image_broken gray tam-100"></i>
            <i v-if="errorImg" class="icon-mks icon_image_broken gray tam-100"></i>
          </div>
        </div>
        <div class="cont-info-product">
          <div class="info-product-modal">
            <span class="text bold size-24">{{ product.name }}</span>
            <span class="text bold size-18">{{ product.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
          </div>
          <div class="product-toppings-content">
            <p id="product-view-description">{{ product.description}}</p>
            <div class="product-toppings-relative" v-for="(subproduct, sp) in product.specs" :key="'subproduct#' + sp">
              <div class="product-item">
                  <div class="cont-title-subproduct">
                     <span class="text bold size-16" :title="subproduct.name">{{ subproduct.name }}</span>
                     <span class="text size-12">Escoge un opción</span>
                  </div>
                <div class="product-item-labels">
                  <span class="text size-12 subProductCont" v-if="subproduct.required" > Obligatorio</span>
                  <span class="text size-12 subProductCont" v-if="subproduct.max > 1"> Max {{ subproduct.max }}</span>
                  <span class="text size-12 subProductCont" v-if="subproduct.min > 0" > Min {{ subproduct.min }}</span>
                </div>
              </div>
              <div class="topping-content" v-if="subproduct.max == 1">
                <div class="input-radio" v-for="(item, spi) in subproduct.items" :key="'items#' + spi" :id="subproduct.name +'-'+ product.name"  @click="addItem(subproduct.name, subproduct.max, item, 'unique')">
                  <div class="topping-restaurant rest-radio" :id="'product#' + subproduct.name + '-' + item.name" :class="{active: item.name == selected}">
                    <label>
                      <span class="text segoe" :class="{'not-price': item.price == undefined}">{{ item.name }}</span>
                      <span class="price" v-if="item.price">+{{ item.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="topping-content" v-if="subproduct.max > 1">
                <div class="input-radio" v-for="(item, spi) in subproduct.items" :key="'items#' + spi" :id="subproduct.name + '-' + product.name">
                  <div class="topping-restaurant rest-radio" :id="'product#' + subproduct.name + '-' +  item.name" @click="addItem(subproduct.name, subproduct.max, item, 'multiple')">
                    <label>
                      <span class="text segoe" :class="{'not-price': item.price == undefined}">{{ item.name }}</span>
                      <span class="price" v-if="item.price">+{{ item.price }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="textarea-cont">
              <span class="text bold size-18 margin-bot-10" title="Instrucciones adicionales">¿Algún comentario adicional?</span>
              <div id="topping-instructions">
                <textarea cols="30" id="comments" rows="10" placeholder="Personaliza tu pedido" v-model="observation"></textarea>
              </div>
            </div>
          </div>
          <div class="product-operator-footer">
            <div class="counter-product-light">
              <div class="btn-addons" @click="removeQuantity(product)"><i id="icon-addons" :class="{'disabled':quantity == 1}" class="fas fa-minus"></i></div>
              <span class="text bold size-20 numCant">{{ quantity }}</span>
              <div class="btn-addons" @click="addQuantity(product)"><i id="icon-addons" class="fas fa-plus"></i></div>
            </div>
            <div class="button-add" style="width:inherit">
              <div class="button-agregar" id="button-product-add-by-store" type="button" @click="validateItemCart(product)">
               <span class="text bold size-16">{{ (edit) ? 'Actualizar' : 'Agregar' }}</span><span class="text size-16">{{ (quantity * price) + extra | currency('$ ', 0, { thousandsSeparator: ',' })}}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalStorage from 'store';
import _ from 'lodash';
import { mapGetters } from 'vuex';
export default {
  props: ['product', 'index'],
  data () {
    return {
      loading: false,
      quantity: 1,
      extra: 0,
      observation: null,
      price: 0,
      selected: [],
      errorImg: false,
      edit: false,
      specsObli: false,
      messAdition: false
    };
  },
  mounted () {
    this.price = this.product.price;
    if (this.product.edit) {
      this.selectedProducts(this.product.options.products);
      this.edit = true;
      this.selected = this.product.options.products;
      this.quantity = this.product.quantity;
      this.observation = this.product.options.observation;
    }
    if (this.product.specs.length === 0) {
      this.specsObli = true;
    }
  },
  computed: {
    ...mapGetters([
      'getVendor',
      'getCart'
    ])
  },
  methods: {
    error (img) {
      this.errorImg = true;
    },
    selectedProducts (products) {
      products.map((item) => {
        const key = item.name;
        item.items.map((data) => {
          const dom = document.getElementById('product#' + key + '-' + data.item);
          dom.classList.add('active');
        });
      });
    },
    addQuantity (product) {
      if (this.validateProductPromo(product)) {
        this.quantity += 1;
      }
    },
    removeQuantity (product) {
      if (this.validateProductPromo(product)) {
        if (this.quantity > 1) {
          this.quantity -= 1;
        }
      }
    },
    validateProductPromo (product) {
      const cartProducts = this.getCart.products;
      let cartLength = 0;
      if (cartProducts !== undefined) {
        cartLength = cartProducts.length;
      }
      const maxPromo2x1 = this.getVendor.maxPromo2x1;
      const maxPromo3x2 = this.getVendor.maxPromo3x2;
      let promo2x1 = 0;
      let promo3x2 = 0;
      if (cartLength === 0 || product.deal.length === 0 || (maxPromo2x1 === undefined && product.deal[0].type === '2x1') || (maxPromo3x2 === undefined && product.deal[0].type === '3x2')) {
        return true;
      } else {
        _.forEach(cartProducts, function (pd) {
          if (Object.keys(pd.deal).length > 0) {
            if (pd.deal[0].type === '2x1') {
              promo2x1 = promo2x1 + pd.quantity;
            }
            if (pd.deal[0].type === '3x2') {
              promo3x2 = promo3x2 + pd.quantity;
            }
          }
        });
        const total2x1 = Math.floor(promo2x1 / 1);
        const total3x2 = Math.floor(promo3x2 / 1);
        if (total2x1 < maxPromo2x1 && product.deal[0].type === '2x1') {
          return true;
        }
        if (total3x2 < maxPromo3x2 && product.deal[0].type === '3x2') {
          return true;
        }
      }
      return false;
    },
    addItem (key, max, item, type) {
      const dom = document.getElementById('product#' + key + '-' + item.name);
      if (type === 'unique') {
        const pos = _.findIndex(this.selected, { name: key });
        if (pos >= 0) {
          const exists = _.findIndex(this.selected[pos].items, { item: item.name });
          if (exists >= 0) {
            if (this.selected[pos].items[exists].price > 0) {
              this.extra -= parseInt(this.selected[pos].items[exists].price);
            }
            this.selected.splice(pos, 1);
            dom.classList.remove('active');
          }
        } else {
          const info = {};
          info.name = key;
          info.items = [];
          const data = {
            item: item.name,
            price: item.price || 0
          };
          if (item.price > 0) {
            this.extra += parseInt(item.price);
          }
          info.items.push(data);
          this.selected.push(info);
          dom.classList.add('active');
        }
      }

      if (type === 'multiple') {
        const pos = _.findIndex(this.selected, { name: key });
        if (pos >= 0) {
          const exists = _.findIndex(this.selected[pos].items, { item: item.name });
          if (exists >= 0) {
            if (this.selected[pos].items[exists].price > 0) {
              this.extra -= parseInt(this.selected[pos].items[exists].price);
            }
            if (this.selected[pos].items.length === 1) {
              this.selected.splice(pos, 1);
            } else {
              this.selected[pos].items.splice(exists, 1);
            }
            dom.classList.remove('active');
          } else {
            if (this.selected[pos].items.length < max) {
              const data = {
                item: item.name,
                price: item.price || 0
              };
              if (item.price > 0) {
                this.extra += parseInt(item.price);
              }
              this.selected[pos].items.push(data);
              dom.classList.add('active');
            } else {
              this.$buefy.toast.open({
                duration: 800,
                message: 'llegó al máximo de adiciones',
                position: 'is-top',
                type: 'is-danger'
              });
            }
          }
        } else {
          const info = {};
          info.name = key;
          info.items = [];
          const data = {
            item: item.name,
            price: item.price || 0
          };
          if (item.price > 0) {
            this.extra += parseInt(item.price);
          }
          info.items.push(data);
          this.selected.push(info);
          dom.classList.add('active');
        }
      }
    },
    validateItemCart (product) {
      const radio = [];
      this.specsObli = false;
      if (product.specs !== undefined) {
        if (product.specs.length > 0) {
          product.specs.map((item) => {
            const vari = {};
            if (item.required === true) {
              vari.name = item.name;
              vari.max = item.max;
              vari.min = item.min || 0;
              radio.push(vari);
            }
          });
        } else {
          this.specsObli = true;
        }
      }
      if (this.selected.length > 0) {
        const total = radio.length;
        let sig = 0;
        radio.map((item, index) => {
          const indexP = _.findIndex(this.selected, { name: item.name });
          if (indexP >= 0) {
            if (item.min > 0) {
              if (this.selected[indexP].items.length === item.min) {
                sig++;
              }
            } else if (item.max > 0) {
              if (this.selected[indexP].items.length === item.max) {
                sig++;
              } else {
                if (item.min === 0) {
                  sig++;
                }
              }
            }
          }
        });
        if (total > 0 && total === sig) {
          this.specsObli = true;
        } else {
          this.specsObli = false;
        }
      }
      if (radio.length === 0) {
        this.specsObli = true;
      }
      if (this.specsObli) {
        if (Object.keys(product.deal).length > 0 && Object.keys(this.selected).length > 0) {
          if (product.deal[0].type === '2x1') {
            const dosXuno = _.orderBy(this.selected[0].items, ['price'], ['desc']);
            const p = dosXuno[1].price;
            this.extra -= p;
            dosXuno[1].price = 0;
          }
          if (product.deal[0].type === '3x2') {
            const tresXdos = _.orderBy(this.selected[0].items, ['price'], ['desc']);
            const p = tresXdos[2].price;
            this.extra -= p;
            tresXdos[2].price = 0;
          }
        }
        let addOrUpdated = true;
        addOrUpdated = this.validateProductPromo(product);
        if (addOrUpdated) {
          if (product.edit) {
            const data = {};
            delete product.edit;
            product.options.extra = this.extra;
            product.options.observation = this.observation;
            product.quantity = this.quantity;
            data.product = product;
            data.index = this.index;
            data.products = this.selected;
            this.updateToCartProduct(data);
          } else {
            this.addToCartProduct(product);
          }
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'No se pueden agregar más productos de promoción al carrito',
            position: 'is-bottom',
            type: 'is-danger'
          });
        }
      } else {
        const thes = this;
        this.messAdition = true;
        setTimeout(function () {
          thes.messAdition = false;
        }, 3000);
      }
    },
    addToCartProduct (product) {
      const info = {};
      const data = {};
      const oldPrice = parseInt(product.price);
      data.products = this.selected;
      data.extra = this.extra;
      data.observation = this.observation;
      product.options = data;
      product.quantity = this.quantity;
      info.sessionId = LocalStorage.get('domicompro-uid-user');
      info.productId = product._id;
      const price = parseInt(product.price) + this.extra;
      product.price = price;
      info.products = product;
      this.loading = true;
      this.$http.post('/marketplace/client/cart/add', info).then(({ data }) => {
        this.loading = false;
        product.price = oldPrice;
        this.$store.commit('setCart', data.data);
        this.$buefy.toast.open({
          duration: 1000,
          message: 'Producto agregado al carrito',
          position: 'is-top',
          type: 'is-success'
        });
        this.$parent.close();
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    updateToCartProduct (info) {
      info.product.options.observation = this.observation;
      this.loading = true;
      this.$http.put('/marketplace/client/cart/update/' + LocalStorage.get('domicompro-uid-user') + '/product/' + info.product._id, { product: info.product, index: info.index }).then(({ data }) => {
        this.loading = false;
        this.$store.commit('setCart', data.data);
        this.$buefy.toast.open({
          duration: 1000,
          message: 'Producto actualizado al carrito',
          position: 'is-top',
          type: 'is-success'
        });
        this.$parent.close();
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.minWidth {
      overflow: auto;
      border-radius: 10px;
      height: 100%;
}
.productView {
  .cont-view-product {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: row;
    font-size: 14px;
    width: 100%;
    text-align: left;
    flex-wrap: wrap;
  }
  .cont-img-product {
    flex: .9;
    min-width: 300px;
  }
  .content-img {
    display: flex;
    align-items: center;
    justify-content: center;//space-around;
    flex-direction: column;
    padding: 20px 0;
    background-color: #fff;
    border-radius: 4px 0 0 4px;
    background-size: cover;
    height: 100%;
    font-size: 0;
  }
  .cont-view-product .cont-img-product .content-img img {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }
  .cont-view-product .cont-img-product .content-img img {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }
  .cont-info-product {
    width: auto;
    height: 100%;
    flex: 1;
  }
  .info-product-modal {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .info-product-modal h2 {
    font-size: 28px;
    line-height: 1.25;
    font-weight: 400;
  }
  .product-toppings-content {
    -webkit-overflow-scrolling: touch;
    width: 100%;
    flex: 1;
    max-height: 450px;
    overflow: hidden;
    overflow-y: auto;
  }
  .product-toppings-relative {
    position: relative;
  }
  .product-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    min-height: 40px;
    margin-top: 10px;
    padding: 5px 10px;
    background: #ECECEC;
  }
  .product-item-labels {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
  }
  .iconf {
    font-family: icomoon!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--buttonBackground);
    margin-left: 10px;
  }
  .topping-content {
    padding: 5px;
  }
  .input-radio {
    padding: 1px 0;
  }
  .topping-restaurant.rest-radio, .topping-restaurant.rest-checkbox {
    display: inline-block;
    vertical-align: middle;
    margin-right: 25px;
    width: 100%;
    margin-bottom: 0;
    height: auto;
  }
  .topping-restaurant.rest-radio {
    transition: all .3s linear;
  }
  .topping-restaurant.rest-radio label {
    font-size: 0;
    position: relative;
    cursor: pointer;
    display: block;
  }
  .topping-restaurant.rest-radio label::before {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #C1C1C1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: all .4s;
    cursor: pointer;
    border-radius: 50%;
    background: 0 0;
  }
  .topping-restaurant.rest-radio label span.not-price {
    width: 100%;
  }
  .topping-restaurant.rest-radio label::after {
    transition: all .3s linear;
    content: "";
    width: 6px;
    height: 6px;
    background: #fff;
    transform: scale(0);
    position: absolute;    transform: scale(1);
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
  }
  .topping-restaurant.rest-checkbox label p, .topping-restaurant.rest-checkbox label span, .topping-restaurant.rest-radio label p, .topping-restaurant.rest-radio label span {
    width: calc(100% - 74px);
    line-height: 18px;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
  }
  .required {
    color: #59626d;
    font-size: 12px;
    display: inline-block;
    float: right;
    background: #e6e6e1;
    padding: 5px 10px;
    border-radius: 14px;
    font-weight: 100;
  }
  .product-toppings-content h3 {
    color: #332927;
    width: 100%;
  }
  .topping-description {
    font-size: 16px;
    padding-bottom: 5px;
    text-align: left;
    font-family: Poppins-SemiBold,Helvetica,Arial,sans-serif;
    margin: 25px 0 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .topping-description {
    font-weight: 700;
  }
  #product-view-description {
    font-size: 14px;
    color: #7d7d7d;
  }
  .product-operator-footer {
    overflow: hidden;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

  }
  .product-operator-footer .button-add {
    float: right;
    width: 190px;
    max-width: 60%;
    &.disabled{
      opacity: .6;
    }
  }
  .product-operator-footer .counter-product-light {
    float: left;
  }
  .counter-product-light>button:disabled {
    opacity: 0.5;
  }
  .counter-product-light>div {
    font-size: 14px;
    text-transform: lowercase;
    font-family: Poppins-SemiBold,Helvetica,Arial,sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    color: #332927;
  }
  .counter-product-light {
    width: 105px;
    max-width: 40%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2px;
    border: 1px solid #CFCDCD;
    border-radius: 5px;
  }
  .counter-product-light>button {
    border: inherit;
    color: #ffffff;
    background-color: #151e26;
    border-radius: 10px;
    width: 34px;
    height: 34px;
    font-family: Poppins-SemiBold,Helvetica,Arial,sans-serif;
    transition: all .3s linear;
    cursor: pointer;
  }
  .counter-product-light>button:disabled:hover {
    cursor: inherit;
    color: #ffffff;
    background-color: #151e26;
  }
  .counter-product-light>button:hover {
    color: #ffffff;
    background-color: #151e26;
  }
  .product-operator-footer .button-add button {
    color: #ffffff;
    border-radius: 10px;
    text-align: left;
    width: 100%;
  }
  .primary-button-filled {
    background-color: #151e26;
    border-radius: 10px;
    font-family: Poppins-Regular,Helvetica,Arial,sans-serif;
    color: #ffffff;
    padding: 8.5px 14px;
    text-align: center;
    cursor: pointer;
  }
  .primary-button-filled svg {
    margin-right: 5px;
  }
  .product-operator-footer .button-add button small {
    float: right;
  }
  .close-cont-info {
    color: #332927;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: absolute;
    right: 15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    font-size: 8px;
    top: 10px;
  }
  #iconClose {
    color: #151e26;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0;
  }
}
.card-content {
  background-color: #ffffff;
}
button {
  box-shadow: none;
  outline: none;
}
.capitalize {
  text-transform: capitalize;
}
.topping-restaurant.rest-checkbox label, .topping-restaurant.rest-radio label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  line-height: 1.71;
  color: #7d7d7d;
  padding-right: 30px;
  font-family: Poppins-Regular,Helvetica,Arial,sans-serif;
  margin: 0;
  text-align: left;
}
.topping-restaurant.rest-checkbox label {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.topping-restaurant.rest-checkbox label::before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 1px;
  z-index: 0;
  border: 2px solid #e5edef;
  width: 15px;
  height: 15px;
  border-radius: 4px;
}
.topping-restaurant.rest-checkbox label::after {
  content: "";
  display: block;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 8px;
  z-index: 1;
  left: 6px;
  width: 5px;
  height: 7px;
  border: solid var(--buttonBackground);
  border-width: 0 3px 3px 0;
  border-left-color: #fff;
  transform: rotate(45deg);
  box-shadow: 1 0 0 1px #fff;
  background: #fff;
}
.topping-restaurant.rest-checkbox label p.price, .topping-restaurant.rest-checkbox label span.price, .topping-restaurant.rest-radio label p.price, .topping-restaurant.rest-radio label span.price {
  color: #9faab7;
  text-align: right;
  width: 66px;
}
.topping-restaurant.active.rest-checkbox label::before {
  border-color: var(--buttonBackground);
  background-color: var(--buttonBackground);
}
.topping-restaurant.rest-checkbox label:hover::after {
  opacity: 1;
  visibility: visible;
  border-color: var(--buttonBackground);
}
.topping-restaurant.active.rest-checkbox label::after {
  opacity: 1;
  visibility: visible;
  border-color: #fff #fff #fff transparent;
  box-shadow: 1px 0 0 1px transparent;
  background: 0 0;
}
// .topping-restaurant.active.rest-radio label::before {
//   border: 1px solid var(--buttonBackground);
//   background: var(--buttonBackground);
//   padding: 2px;
// }
.topping-restaurant.active.rest-radio label::after {
  transform: scale(1.5);
  background: var(--buttonBackground);
}
#topping-instructions textarea {
  padding: 10px;
  width: 100%;
  resize: none;
  height: 70px;
  border: 1px solid #CFCDCD;
  font-size: 12px;
  font-family: 'segoe';
  color: #000;
  display: block;
  margin: 10px 0;
}
::-webkit-scrollbar{
    width: 0!important;
    height: 0 !important;
}
.cont-title-subproduct{
    display: flex;
    flex-direction: column;
}
.text{
    &.subProductCont{
    border: 1px solid rgba(112, 112, 112, 0.3);
    padding: 2px 5px;
    color: #707070;
    margin-right: 5px;
    }
    &.numCant{
    display: flex;
    align-items: center;
    }
}
.textarea-cont{
    margin: 10px 0;
}
.btn-addons{
    height: 35px;
    width: 30px;
    position: relative;
    #icon-addons{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    }
}
.button-add{
    .button-agregar{
    width: 160px;
    border: inherit;
    display: flex;
    justify-content: space-between;
    max-width: 160px;
    height: 41px;
    padding: 7px 15px;
    border-radius: 6px;
    background: #CDD500;
    margin-left: 10px;
    cursor: pointer;
    }
}
</style>
