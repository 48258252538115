import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
    meta: {
      title: 'Restaurantes'
    }
  },
  {
    path: '/:id/restaurant',
    component: () => import(/* webpackChunkName: "restaurant" */ '@/views/Restaurant.vue'),
    meta: {
      title: 'Restaurantes'
    }
  },
  {
    path: '/:id/order',
    component: () => import(/* webpackChunkName: "restaurant" */ '@/views/Order.vue'),
    meta: {
      title: 'Orden'
    }
  },
  {
    path: '/:id/menu-digital',
    component: () => import(/* webpackChunkName: "restaurant" */ '@/views/MenuDigital.vue'),
    meta: {
      title: 'Menú Digital'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "error404" */ '@/views/404.vue'),
    meta: {
      title: 'No se encuentra'
    }
  },
  {
    path: '/wrong-device',
    name: 'wrong-device',
    component: () => import(/* webpackChunkName: "error404" */ '@/views/BadDevice.vue'),
    meta: {
      title: 'Dispositivo Incorrecto'
    }
  },
  {
    path: '/:id/orderQuery',
    component: () => import(/* webpackChunkName: "restaurant" */ '@/views/QueryOrder.vue'),
    meta: {
      title: 'Consulta orden'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (!to.matched.length) {
    next({
      name: '404'
    });
  }
  next();
});

export default router;
