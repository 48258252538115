<template>
  <b-modal :active.sync="value" :can-cancel="true" class="ModalTransaction" >
      <div class="modals-bosy">
          <div class="col-12 text-center icon-padding">
            <IconTemporal fill="#d99332" width="20em"></IconTemporal>
          </div>
          <div>
            <h3 class="title-modal">Transacción en Proceso:</h3>
            <div class="col-12 text-lef-messages">
              <h5 class="title-confirm">
                Estamos validando el pago
              </h5>
              <p>
                La aprobación de la transacción puede tardar unos minutos, por favor no cierres esta página volveremos a validar el pago en <b>10 segundos</b>.
              </p>
            </div>
            <div class="btns-modal">
              <button
                type="button"
                @click="goOrder()"
                class="full-btn-modal btn-modal-fill">&nbsp; Cerrar</button>
            </div>
          </div>
      </div>
  </b-modal>
</template>
<script>
import IconTemporal from '@/components/icons/iconTemporal';
export default {
  components: { IconTemporal },
  props: ['value'],
  methods: {
    goOrder () {
      window.localStorage.removeItem('processId');
      this.$emit('clear', true);
      this.$emit('input', false);
    },
    reintentar () {
      this.$emit('input', false);
      window.location.reload();
    }
  }
};
</script>
<style lang="scss" scoped>
.ModalTransaction{
   .icon-padding{
     margin-bottom:20px;
   }
  .modals-bosy {
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .btns-modal{
      margin-top: 15px !important;
      display: flex;
      flex-wrap: wrap;
      button{
        width: 364px !important;
        padding: 10px !important;
        margin: auto !important;
        text-align:center;
        margin-top:10px !important;
      }
    }
  }
}
</style>
